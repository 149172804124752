import { Component, OnInit } from '@angular/core';
import { HeaderService } from './header.service';
import { Location } from '@angular/common';
import { HttpClient } from '@angular/common/http';
import { Router } from '@angular/router';
import { ActivatedRoute } from '@angular/router';

import * as jQuery from 'jquery';
import { trigger, transition, animate, style, state, group } from '@angular/animations';
import { MagentoService } from 'src/app/magento.service';
import { CandyService } from 'src/app/candy.service';
import { THIS_EXPR } from '@angular/compiler/src/output/output_ast';
import { MessageService } from '../message/message.service';
import { UtilService } from 'src/app/util.service';
import { SessionService } from 'src/app/session.service';

@Component({
	selector: 'site-header',
	templateUrl: './header.component.html',
	styleUrls: ['./header.component.scss'],
	animations: [
		trigger('fadeInOut', [
			transition(':leave', [   // :leave is alias to '* => void'
				animate(600, style({ opacity: 0 }))
			]),
			transition(':enter', [   // :enter is alias to 'void => *'
				style({ opacity: 0 }),
				animate('600ms 600ms ease-out', style({ opacity: 1 }))
			])
		]),
		trigger('fadeIn', [
			transition(':leave', [   // :leave is alias to '* => void'
				animate(600, style({ opacity: 0 }))
			]),
			transition(':enter', [   // :enter is alias to 'void => *'
				style({ opacity: 0, position: 'absolute' }),
				animate('600ms 900ms ease-out', style({ opacity: 1, position: 'relative' }))
			])

		]),
		trigger('fadeOut', [
			transition(':leave', [   // :leave is alias to '* => void'
				animate(600, style({ opacity: 0 }))
			])

		]),
    trigger('slideInOut', [
      state('in', style({height: '*', opacity: 0})),
      transition(':leave', [
          style({height: '*', opacity: 1, 'overflow':'auto'}),
          group([
              animate(400, style({height: 0})),
              animate('400ms', style({'opacity': '0', 'overflow':'hidden', 'overflow-y':'hidden'}))
          ])

      ]),
      transition(':enter', [
          style({height: '0', opacity: 0, overflow:'hidden'}),
          group([
              animate(400, style({height: '*'})),
              animate('400ms', style({'opacity': '1', 'overflow':'auto', 'overflow-y':'hidden'}))
          ])

      ])
  ])
	],
})
export class HeaderComponent implements OnInit {

	showHeader = null;
	showPasswordReset = null;
	showCookiePopup: Boolean = false;
	showCartDot = null;
	routerLink: String = "";

	showSubMenu: boolean = false;
	showLoader = null;

	categories: Array<any> = [];
	selectedCat: any = null;
	brands: Array<any> = [];
	showMessage = null;

	showMobileSubMenu: boolean = false;

	token: string = null;

	constructor(private headerService: HeaderService, private util: UtilService, private _location: Location, private http: HttpClient, private router: Router, private route: ActivatedRoute, private magento: MagentoService, private candy: CandyService, private message: MessageService, private session: SessionService) {

	}

	ngOnInit() {
		this.headerService.showLoader.subscribe(showLoader => {
			this.showLoader = showLoader;
		});

		this.headerService.showHeader.subscribe(showHeader => {
			this.showHeader = showHeader;
		});

		this.headerService.showPasswordReset.subscribe(showPasswordReset => {
			this.showPasswordReset = showPasswordReset;
		});

		this.headerService.showMessage.subscribe(showMessage => {
			this.showMessage = showMessage;
		});

		this.headerService.showCartDot.subscribe(showCartDot => {
			this.showCartDot = showCartDot;
		});

		if(this.session.isLoggedIn()) {
			this.session.getCategories();
		}

		jQuery(document).on("click", ".nav-link a", function () {
			jQuery('header').removeClass('menu-open');
		});

		if (localStorage.getItem("cart") &&  JSON.parse(localStorage.getItem("cart"))['item_count'] > 0 ) {
			this.showCartDot = true;
		} else {
			this.showCartDot = false;
		}
	}

	toggleMobilesSubMenu() {
		jQuery('.mobile-submenu-2').toggleClass('menu-open');
		jQuery('header').removeClass('menu-open');
	}

 	menuToggle() {
		jQuery('header').toggleClass('menu-open');		
		this.categories = this.session.categories;
		this.brands = this.session.brands;
	}

	setShowSubMenu() {
		this.showSubMenu = true;
		this.categories = this.session.categories;
		this.brands = this.session.brands;
	}

	openLoginPopup() {
		if (localStorage.getItem("user") && JSON.parse(localStorage.getItem("user"))['CustomerID'] > 0) { // if logged in
			this.router.navigateByUrl('/user/tradein');
		} else {
			jQuery('.loginpopup-overlay').fadeIn().css('display', 'flex');
		}
	}

	closeCookiePopup(){
		this.showCookiePopup = false;
	}

	scrollDown(){
		var height = window.innerHeight / 2;
		window.scrollTo( 0, height );
	}
}

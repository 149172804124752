import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { FooterService } from '../global/footer/footer.service';
import { HeaderService } from '../global/header/header.service';
import { MessageService } from '../global/message/message.service';
import { MagentoService } from '../magento.service';
import { SessionService } from '../session.service';
import * as jQuery from 'jquery';
import { CandyService } from '../candy.service';
import { UtilService } from '../util.service';


@Component({
  selector: 'app-passwordreset',
  templateUrl: './passwordreset.component.html',
  styleUrls: ['./passwordreset.component.scss']
})
export class PasswordresetComponent implements OnInit {
  loginUsername: string = null;
  loginPassword: string = null;
  showUserDNE: Boolean = false;
  errorMessage: string = null;
  successMessage: string = null;
  showRegisterPopup: Boolean = false;

  constructor(private router: Router, private headerService: HeaderService, private footerService: FooterService, private message: MessageService, private session: SessionService, private candy: CandyService, private util: UtilService) {
    this.headerService.setShowHeader(false);
    this.footerService.setShowFooter(false);
  }

  togglePopup() {
    this.showRegisterPopup = true;
  }

  ngOnInit() {
    if(this.session.isLoggedIn()){
      this.router.navigateByUrl('/account');
    }
  }

  processReset(){
    this.headerService.setShowLoader(true);

    this.candy.createRequest('POST', 'api/v1/forget-password', {'email':this.loginUsername}).subscribe(response => {
      console.warn(response);
        if(response){
          this.successMessage = response['message'];
        }
          this.headerService.setShowLoader(false);
      }, error => {
      console.warn(error);
      this.showUserDNE = true;
      this.errorMessage = error.error.message;
      this.message.showMessage('No active user exists with this email', 'error');
      this.headerService.setShowLoader(false);
    })
  }
}

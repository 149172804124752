import { Component, OnDestroy, OnInit } from '@angular/core';
import { HeaderService } from 'src/app/global/header/header.service';
import { FooterService } from '../global/footer/footer.service';
import { Router } from '@angular/router';
import { HomepageService } from './homepage.service';

import * as jQuery from 'jquery';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { MagentoService } from '../magento.service';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { MessageService } from '../global/message/message.service';

import { DomSanitizer, SafeResourceUrl, SafeUrl } from '@angular/platform-browser';
import { Title, Meta } from '@angular/platform-browser';
import * as slider from '../../assets/js/fusion-slider';
import { CandyService } from '../candy.service';
import { LoaderComponent } from '../global/loader/loader.component';
declare var Slider: any;

@Component({
  selector: 'app-homepage',
  templateUrl: './homepage.component.html',
  styleUrls: ['./homepage.component.scss']
})
export class HomepageComponent implements OnInit, OnDestroy {

  loginUsername: String = null;
  loginPassword: String = null;
  showValidationError: boolean = false;
  showUserDNE: boolean = false;
  loadAPI: boolean = false;
  showPasswordReset = null;
  errorMessage: String = null;
  brands: Array<any> = [];
  brandsData: Array<any> = [];

  currentSelectedBrand: String = null;
  currentSelectedBrandIndex: String = null;
  currentSelectedBrandLogo: any = './assets/img/brand-';

  currentMostPopularDevices: Array<any> = [];

  popularDevices: Array<any> = [];

  searchCriteria: String = null;
  searchResults: Array<any> = [];
  searchFinished: Boolean = false;
  account_url: String = '/login';
  categoryName: String = "";
  showLoader = null;
  showDropdown = false;
  showSearchLoader = null;
  showResults: Boolean = false;

	homeSlider: any = null;
  loadFusionSlider: boolean = false;

  showDownloadTooltip: boolean = false;

  protected ngUnsubscribe: Subject<void> = new Subject<void>();

  constructor(private headerService: HeaderService, private footerService: FooterService, private router: Router,  private login: HomepageService, private http: HttpClient, private magento: MagentoService, private message: MessageService, private candy: CandyService) { }

  ngOnInit() {
    this.headerService.setShowHeader(true);
    this.footerService.setShowFooter(true);
    this.headerService.setShowLoader(true);

    jQuery(document).scroll(function () {
			var scrolled = (jQuery(document).scrollTop() / 4);
			jQuery('.anim-banner').css('background-position', 'center ' + scrolled + 'px');
		});

    this.candy.createRequest('GET' , 'api/modules/popular').subscribe(res => {
      this.brands =  res;

      if (this.brands) {
        this.setMostPopularBrand( this.brands[0] ,0);
      }

      // get images via gsm arena
      this.brands.forEach((brand) => {
        brand['products'].forEach((product) => {          
          this.candy.getProductImageUrl(product['attribute_data']['model-id'].webstore.en).then((res)=>{
            product['img_url'] = res;
          })
        })
      })

      this.headerService.setShowLoader(false);
    }, error => {
      if(error['status'] === 503) {
				this.message.showMessage('Prices are currently being updated. Please try again later.', 'error');
				this.candy.customerLogout();
			} else {
				this.message.showMessage(error.error.message, 'error');
			}
			this.headerService.setShowLoader(false);
    })
  }

  ngOnDestroy() {
		this.homeSlider = null;
	}

  setMostPopularBrand(brandObject, brandIndex) {
    // only work if most popular category is not null
    if (brandObject) {
      this.headerService.setShowLoader(true);

      this.currentSelectedBrandIndex = brandIndex;

      this.currentSelectedBrand = brandObject['attribute_data']['name'].webstore.en;

      this.currentMostPopularDevices = brandObject['products'];

      this.currentSelectedBrandLogo = `./assets/img/brand-${this.currentSelectedBrand.toLowerCase()}.jpg`;

      this.homeSlider = null;

      var ng = this;

      jQuery(document).ready(function () {
        if (!this.loadFusionSlider) {
          this.homeSlider = new Slider(jQuery);
          this.homeSlider.init('.phone-slider-container', '.phone-slide-outer', null, 9000, false, '.phone-slider-controls', 1, false);
          this.loadFusionSlider = true;
        }
      });
      ng.headerService.setShowLoader(false);
    }
  }

  getProductUrl(product){
    if(product['attribute_data']['sku'].webstore.en){
      return '/products/' + this.currentSelectedBrand + '/product/' + product['url'];
    } else {
      return false;
    }
  }

  getImageUrl(modelID) {
    return 'https://o2-api.devicetradein.co.uk/api/cache/images/model/' + modelID + '_1_pv.png'
  }

  clearSearch(){
    setTimeout(()=>{
      this.showDropdown = false
    }, 400)
  }

  processSearch(){
    this.ngUnsubscribe.next();

    if(this.searchCriteria.length >= 3){
      this.showDropdown = true;
      this.showSearchLoader = true;
      this.searchFinished = false;
      var request = 'api/search/?parents=1&q=';

      this.candy.createRequest('GET', request + this.searchCriteria).subscribe(response => {
        if (response) {
          this.searchResults = Object.values(response[0]['data']);
          this.showResults = true;

          setTimeout(()=>{
            this.showSearchLoader = false;
          }, 1000);

          this.searchFinished = true;
        } else {
          this.searchFinished = true;
          this.showSearchLoader = false;
        }
      })
    }
  }

  selectResult(product){
    if(product['custom_attributes'].find(array => array.attribute_code === 'url_key')){
      var url = '/products/brand/product'+product['custom_attributes'].find(array => array.attribute_code === 'url_key')['value'];
      this.showResults = false;
      this.showDropdown = false;
      this.searchCriteria = null;
      this.router.navigateByUrl(url);
    } else {
      this.message.showMessage('Unable to view product', 'error');
    }
  }

  updateAndDownloadStock() {
    this.headerService.setShowLoader(true);
    this.candy.createRequest('GET-CSV', 'api/modules/export/products', {responseType: 'blob'}).subscribe(response => {
      this.headerService.setShowLoader(false);
      const blob = new Blob([response], { type: 'text/csv' });
      const url = window.URL.createObjectURL(blob);
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', 'exportCsv.csv');
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
      window.URL.revokeObjectURL(url);
    }, error => {
      console.warn('Error during stock update and download:', error);
      this.headerService.setShowLoader(false);
    });
  }
}



import { Injectable } from '@angular/core';
import { CandyService } from './candy.service';
import { HeaderService } from './global/header/header.service';
import { MessageService } from './global/message/message.service';
import { UtilService } from './util.service';

@Injectable({
  providedIn: 'root'
})
export class SessionService {

  categories: Array<any> = [];
	brands: Array<any> = [];

  constructor(private util: UtilService, private candy: CandyService, private headerService: HeaderService, private message: MessageService) { }

  isLoggedIn(){
    var result = false;

    // NB maybe needed later if this cookie is needed by GC. It was there for MG2 previously
    if(this.util.getCookie('customer')){
      result = true;
    }

    if(this.util.getCookie('customer_token')){
      result = true;
    }

    return result;
  }

  getCategories() {
    this.categories = [];
    this.brands =[];
    
		this.candy.createRequest('GET', 'api/modules/edit/categories').subscribe(response => {

			// running importer clears out category ids so we need to grab root cat id to use parent_id
			// root and popular are always 0 and 1 in cat array respectively
			var rootCatId = response.allCategories[0].id;

			response.allCategories.forEach(cat=> {
				if(cat.parent_id === rootCatId) {
					this.categories.push(cat);
				} else {
					if(cat.id === rootCatId) return;
					this.brands.push(cat);
				}
			})
		}, error => {
			if(error['status'] === 503) {
				this.candy.customerLogout();
			}
			this.headerService.setShowLoader(false);
		})
	}

}

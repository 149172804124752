import { Injectable, ChangeDetectorRef } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { mergeMap } from 'rxjs/operators';
import { from } from 'rxjs';
// import { MessageService } from './global/message/message.service';
import { HeaderService } from './global/header/header.service';
import { UtilService } from './util.service';
import { SessionService } from './session.service';
import { Router } from '@angular/router';
import { MessageService } from './global/message/message.service';
// import { faTreeChristmas } from '@fortawesome/pro-light-svg-icons';

@Injectable({
  providedIn: 'root'
})
export class CandyService {

  // REMOVED AUTH AS A TEST
  //public hostname: String = 'https://clstradesalesapi.ingrammicro.com/';
  //public api_host: String = 'https://clstradesalesapi.ingrammicro.com/';

  // public hostname: String = 'https://get-candy.devicetradein.co.uk/';
  // public api_host: String = 'https://get-candy.devicetradein.co.uk/';

  url: string = window.location.origin.includes("clstradesales.ingrammicro.com")
    ? "https://clstradesalesapi.ingrammicro.com/"
    : "https://get-candy.devicetradein.co.uk/";

  public hostname: string = this.url;
  public api_host: string = this.url;

  constructor(private http: HttpClient, private util: UtilService, private headerService: HeaderService, private router: Router, private messageService: MessageService) {

  }

  createRequest(type = 'GET', url, payload = null, token = this.util.getCookie("customer_token")): Observable<any>{
    return this.getData(type, url, payload, token);
  }

  getData(type = 'GET', url, payload = null, token = this.util.getCookie("customer_token")){
    if(type == 'GET'){
      return this.http.get(this.api_host+url, {headers: {'Authorization': 'Bearer '+token}});
    } else if(type == 'POST') {
      return this.http.post(this.api_host+url, payload, {headers: {'Content-Type': 'application/json; charset=utf-8', 'Authorization': 'Bearer '+token}});
    } else if(type == 'POST-TP') {
      return this.http.post(this.api_host+url, payload, {headers: {'Content-Type': 'application/json; charset=utf-8', 'Authorization': 'Bearer '+token}, responseType:'text'});
    }  else if(type == 'GET-TP') {
      return this.http.get(this.api_host+url, {headers: {'Content-Type': 'application/x-www-form-urlencoded', 'Authorization': 'Bearer '+token}, responseType:'text'});
    } else if (type == 'PUT'){
      return this.http.put(this.api_host+url, payload, {headers: {'Content-Type': 'application/json; charset=utf-8', 'Authorization': 'Bearer '+token}});
    } else if (type == 'PATCH'){
      return this.http.patch(this.api_host+url, payload, {headers: {'Content-Type': 'application/json; charset=utf-8', 'Authorization': 'Bearer '+token}});
    } else if (type == 'DELETE'){
      return this.http.delete(this.api_host+url, {headers: {'Content-Type': 'application/json; charset=utf-8', 'Authorization': 'Bearer '+token}});
    } else if (type == 'GET-CSV'){
      return this.http.get(this.api_host+url, {headers: {'Content-Type': 'text/csv; charset=UTF-8', 'Authorization': 'Bearer '+token}, responseType: 'blob'});
    } else {
      return null;
    }
  }

  customerLogout(){
    // this.headerService.setShowLoader(true);
    localStorage.removeItem('cart');
    localStorage.clear();
    this.util.deleteCookie('customer');
    this.util.deleteCookie('customer_token');
    this.router.navigateByUrl('/login');
    this.messageService.showMessage('You have been logged out.', 'success', true);
    window.location.reload();
    // this.headerService.setShowLoader(false);
  }

  assignCartToUser(newBasketID, product = null) {
    this.createRequest('POST', `api/v1/baskets/${newBasketID}/claim`).subscribe(response => {
      // add product to user's newly assigned cart
      if (product) { this.addToCart(product); }
    });
  }

  addToCart(product, currentDeviceType = null): Promise<any> {
    return new Promise((resolve, reject) => {
      var token = null;
      var url, url2 = null;
      var basketID = null;

      // if product quantity less than available stock then prevent addtocart
      if (product['selected_qty'] > product['stock'] || product['stock'] == 0 ) {
        reject('Requested amount for this product is not available');
      } else {
        if (this.util.getCookie('customer_token')) {
          // Use customer ID, which is their tokenn when logging in, as the basket ID
          basketID = token =  this.util.getCookie('customer_token');
      }

        // check if user has a basket associated with their account or not
        url = "api/v1/baskets/current";

        this.createRequest("GET", url).subscribe(response => {
          console.warn(response);
          basketID = response['data']['id'];

          if (basketID) {
            // user has basket, so use existing basket ID, then add current item to basket
            basketID = response['data']['id'];

            //WIP
            // disable mixing mobiles & other device types in sane cart
            var currentType = null;

            if (response['data']['lines']['data'].length > 0) {
              // need this to return product variant parent category
              // currentType = response['data']['lines']['data'][0]['variant']['data']['options']['vat']['en'];
            }

            // if (currentType != product.variant.parent_category) {
            //   reject('Cannot mix mobiles and other device types in the same basket');
            // }

            // check if user has any items in basket and if so, ensure user is not adding new item that has a different vat type
            // only need to check first item if item in basket, no need to check them all
            let currentVAT = null;

            let currentModelId = null;

            if (response['data']['lines']['data'].length > 0) {
              currentVAT = response['data']['lines']['data'][0]['variant']['data']['options']['vat']['en'];
              currentModelId = response['data']['lines']['data'][0]['variant']['data']['product']['data']['model-id'];
            }

            const selectedProductVAT = product['selected_vat'].charAt(0).toUpperCase() + product['selected_vat'].substring(1);
            if (selectedProductVAT != currentVAT && currentVAT != null) {
              // promise returns reject which will be used as error message
              reject('Cannot mix standard and marginal items in same basket');
              return;
            } else {
              const request = {
                "basket_id": basketID,
                "variants": [
                  {
                    "id": product['selected_variant'],
                    "quantity": product['selected_qty']
                  }
                ]
              };

              if (currentVAT === 'Standard' && currentDeviceType === 'Wearables') {
                if (product['custom_details'] !== null
                  && product['custom_details'].hasOwnProperty('is_cellular')
                  && currentModelId
                ) {
                  let currentProductIsCellular = null;
                  const newProductIsCellular = product['custom_details']['is_cellular'];
                  this.getProductImagesWithCustomDetails(currentModelId).then((res) => {
                    currentProductIsCellular = res['custom_details'] ? res['custom_details']['is_cellular'] : null;
                    if (currentProductIsCellular !== null && newProductIsCellular !== null) {
                      // Check if either the first product or the new product is cellular
                      if ((currentProductIsCellular || newProductIsCellular) &&
                        currentProductIsCellular !== newProductIsCellular) {
                        reject('Cannot mix cellular and non-cellular items in the same basket');
                        return;
                      }
                    }
                  }).catch(error => {
                    console.log(error);
                  });
                }
              }

              url = "api/v1/basket-lines";
              this.createRequest('POST', url, request, token).subscribe(response => {

                   if (response) {

                    var itemCount = response['data']['lines']['data'].length;

                    var data = {
                      basket_id: basketID,
                      variant_id: product['variants'][0]['id'],
                      price: product['price']
                    };

                    this.createRequest('POST', 'api/v1/quotes/update-basket-prices', data).subscribe(response => {
                      if (response) {

                        localStorage.setItem('cart', JSON.stringify({basket_id: basketID, item_count: itemCount}));
                        // product added to cart successfully, so let's make sure it's stock is reduced accordingly
                        this.decreaseStock(product['selected_variant_sku'],product['selected_qty']);
                        this.headerService.setShowLoader(false);
                        this.messageService.showMessage(product['name'] + ' has been added to your basket', 'success', true);
                        this.headerService.setShowCartDot(true);
                        resolve(response);
                      } else {
                        reject(response);
                      }
                    }, error => {
                      this.headerService.setShowLoader(false);
                    });
                  } else {
                    reject(response);
                  }
                }, error => {
                  reject(error['error']['message']);
              });
            }
          } else {
            // user does not have a basket, so let's make a new one for them and assign it
            this.createRequest("POST", "api/v1/baskets").subscribe(response => {
              console.warn('new basket made', response);
              const newBasketID = response['data']['id'];
              this.assignCartToUser(newBasketID, product);
            });
          }
        }, err => {
          reject('Failed to get customer basket');
        });
      }
    });
  }

  removeFromCart(basketLineID, qty, name){
    this.headerService.setShowLoader(true);
    var token = null;
    var url = `api/v1/basket-lines/${basketLineID}/remove`;

    var cart = JSON.parse(localStorage.getItem('cart'));
    var basket_id = cart['basket_id'];

    localStorage.setItem('cart', JSON.stringify({basket_id: basket_id, item_count: cart.item_count-1}));

    var promise = new Promise((resolve, reject) => {
      if (this.util.getCookie('customer_token')) {
        token = this.util.getCookie('customer_token');
      }

      this.createRequest('PUT', url, {'quantity': qty }, token).subscribe(response => {
        this.headerService.setShowLoader(false);
        resolve(name +'has been removed from your basket');
        var itemCount = JSON.parse(localStorage.getItem('cart'))['item_count'];
        if (itemCount <= 0 ) {
          this.headerService.setShowCartDot(false);
        }

      }, response => {
        this.headerService.setShowLoader(false);
        reject(response);
      });
    });

    return promise;
  }

  // fire this endpoints an item is either added or removed from cart so works same as magento - apply stock change immediately instead of until cart traded in
  decreaseStock(sku, qty) {
    return new Promise((resolve, reject) => {
      this.createRequest("POST", "api/v1/stock/decrease", {'sku': sku, 'quantity': qty}).subscribe(response => {
        resolve(response);
      }, err => {
        this.messageService.showMessage(err['error']['error'], 'error');
        resolve(err);
      });
    });
  }

  increaseStock(sku, qty) {
    return new Promise((resolve, reject) => {
      this.createRequest("POST", "api/v1/stock/increase", {'sku': sku, 'quantity': qty}).subscribe(response => {
        resolve(response);
      }, err => {
        resolve(err);
      });
    });
  }

  async getProductImageUrl(modelID) {
    // grab image via GSMArena Scraper
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': 'Bearer 1|HR06B8WUIYkVxNBZHYZjtu7QCkqneiRomxJEkQSl'
      })
    };

    return new Promise((resolve, reject) => {
      // set default as placeholder and return that unless api finds actual image url
      let image = null;
      this.http.post(`https://im-portfolio-api.devicetradein.co.uk/api/get-gsm-details?model_id=${modelID}`, null, httpOptions).subscribe(res => {
        if (res['message']) {
          image = res['message']['images'][0];
        }
        resolve(image);
      }, err => {
        console.warn(err);
        reject('error');
      });
    });
  }

  async getProductImagesWithCustomDetails(modelID) {
    // grab image via GSMArena Scraper
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': 'Bearer 1|HR06B8WUIYkVxNBZHYZjtu7QCkqneiRomxJEkQSl'
      })
    };

    return new Promise((resolve, reject) => {
      // set default as placeholder and return that unless api finds actual image url
      let customDetails = {
        image: null,
        custom_details: null
      };
      this.http.post(`https://im-portfolio-api.devicetradein.co.uk/api/get-gsm-details?model_id=${modelID}`, null, httpOptions).subscribe(res => {
        if (res['message']) {
          customDetails = {
            image: res['message']['images'][0],
            custom_details: res['message']['custom_details']
          };
        }
        resolve(customDetails);
      }, err => {
        console.warn(err);
        reject('error');
      });
    });
  }
}



import { Component, OnInit } from '@angular/core';
import { MagentoService } from 'src/app/magento.service';
import { ActivatedRoute, Router } from '@angular/router';
import { HeaderService } from 'src/app/global/header/header.service';
import { UtilService } from 'src/app/util.service';
import { SessionService } from 'src/app/session.service';
import { OrderSelected } from '../order-selected.service';
import { CandyService } from 'src/app/candy.service';
import { MessageService } from 'src/app/global/message/message.service';

export interface ICustomer {
  name: string;
  addresses: Array<any>;
}

export interface IOrder {
  increment_id: string;
  quote: string;
}

@Component({
  selector: 'app-orders',
  templateUrl: './orders.component.html',
  styleUrls: ['./orders.component.scss', '../account.component.scss']
})


export class OrdersComponent implements OnInit {

  orders: Array<any> = [];

  orderSelected: boolean = false;

  order: IOrder;
  customer: ICustomer;

  listItems: any[] = [
    {'name': 'My Account', 'routerLink': '/account'},
    {'name': 'My Orders', 'routerLink':'/account/orders'},
    {'name': 'My Quotes', 'routerLink':'/account/quotes'},
  ];
  selectedLi: object = this.listItems[1];

  allSKUs: Array<any> = [];
  products: Array<any> = [];

  deliveryMethod: string = null;

  constructor(private magento: MagentoService, private router: Router, private header: HeaderService, private util: UtilService, private session: SessionService, private _route: ActivatedRoute, private candy: CandyService, private message: MessageService) {

  }

  ngOnInit() {
    if(this.session.isLoggedIn()){
      console.log(this.session.isLoggedIn());

      // this.deliveryMethod = OrderSelected.currentOrder['shipping_description'];
      this.header.setShowLoader(true);
      // this.customer = JSON.parse(this.util.getCookie('customer'));

      // GC API
      this.candy.createRequest('GET', 'api/orders/user').subscribe(response => {
        this.orders = response;
        this.header.setShowLoader(false);
      }, error => {
        if(error['status'] === 503) {
          this.message.showMessage('Prices are currently being updated. Please try again later.', 'error');
          this.candy.customerLogout();
        } else {
          this.message.showMessage('Error getting orders', 'error');
        }
        this.header.setShowLoader(false);
      })
      // this.magento.createRequest('GET', 'V1/orders/?searchCriteria[filterGroups][0][filters][0][field]=customer_id&searchCriteria[filterGroups][0][filters][0][value]='+this.customer['id']).subscribe(response => {
      //   if(response['items'].length >= 1){
      //     this.orders = response['items'];

      //     //Get all SKUs then use them to get matching products so their options can be obtained then put into each item
      //     this.orders.forEach(order => {
      //       order['items'].forEach(item => {
      //         this.allSKUs.push(item.sku)
      //         console.warn(this.orders);
      //       });
      //     })
      //     this.allSKUs.forEach(sku => {
      //       this.magento.createRequest('GET', 'V1/products/' + sku ).subscribe(response => {
      //         this.products.push(response);
      //         this.orders.forEach(order => {
      //           order['items'].forEach(item => {
      //             if (item.sku == response.sku) {
      //               item['options'] = response.options
      //             }
      //           })
      //         })
      //       })

      //     })


      //   }


      //   this.header.setShowLoader(false);
      // });

      this.customer = {
        name: '',
        addresses: []
      }

    } else {
      this.router.navigateByUrl('/login')
    }

    if (OrderSelected.currentOrder) {
      this.orderSelected = true;
      this.order = OrderSelected.currentOrder;
      this.deliveryMethod = OrderSelected.currentOrder['shipping_description'].replace(/[^-]*$/, "").replace(" -", "");
    }
  }

  selectListItem(item) {
    this.selectedLi = item;
    this.orderSelected = false;
  }

  selectOrder(order) {
    this.orderSelected = true;
    this.order=order;

    this.order['sub_amount'] = 0;
    this.order['quantity'] = 0;

    this.order['order_content'] = JSON.parse(this.order['order_content']);

    this.order['order_content'].forEach((a)=> {
      this.order['sub_amount'] =+ a.price;
      this.order['quantity'] += a.quantity;
    })

    console.warn(this.order);

    // this.candy.createRequest('POST', 'api/orders/single', {'id': order.id}).subscribe(response=> {

    // })

    // this.deliveryMethod = order['shipping_description'];
    this.deliveryMethod = 'Standard Delivery';
  }

  logOut() {
    this.candy.customerLogout();
  }
}

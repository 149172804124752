import { Component, OnInit } from '@angular/core';
import { MessageService } from './message.service';
import { Subscription } from 'rxjs';
import { HeaderService } from '../header/header.service';

@Component({
  selector: 'app-message',
  templateUrl: './message.component.html',
  styleUrls: ['./message.component.scss']
})
export class MessageComponent implements OnInit {

  messageSub: Subscription;
  message: String = null;
  typeSub: Subscription;
  type: String = null;

  constructor(private messageService: MessageService, private headerService: HeaderService) { }

  ngOnInit() {
    this.messageSub = this.messageService.message.subscribe(message => this.message = <String>message);
    this.typeSub = this.messageService.type.subscribe(type => this.type = <String>type);
  }

  closeMessage(){    
    this.headerService.setShowMessage(false);
    setTimeout(() => {
      this.messageService.message.next(null);
      this.messageService.type.next(null);
    }, 1000);
  }

}

import { Injectable } from '@angular/core';
// This service is for passing orders from the account component to the orders component on click
@Injectable({
  providedIn: 'root'
})
export class OrderSelected {
    public static currentOrder: any;
    public static currentQuote: any;
  constructor() { }
}

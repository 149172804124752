import { Component, OnInit, ViewChild } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { CandyService } from '../candy.service';
import { UtilService } from '../util.service';

@Component({
  selector: 'app-threeds-redirect',
  templateUrl: './threeds-redirect.component.html',
  styleUrls: ['./threeds-redirect.component.scss']
})

export class ThreedsRedirectComponent implements OnInit {
  @ViewChild('myForm', {static: false}) form: any;
  PaReq: String = ""
  TermUrl: String = ""
  ascURL: String = ""
  sessionID: String = ""
  creq: any = ""
  threeDSRef: any = ""

  transactionUnique: string = "";

  addresses: Array<any> = [];
  userID: number = null;
  orderID: any = null;

  constructor(private route: ActivatedRoute, private util: UtilService, private candy: CandyService) { }

  ngOnInit() {
    // this.PaReq = this.route.snapshot.queryParams['oneTime3DsToken'];
    // this.sessionID = this.route.snapshot.queryParams['sessionID'];
    // this.TermUrl = this.route.snapshot.queryParams['threeDSURL'];
    this.ascURL = this.route.snapshot.queryParams['threeDSURL'];
    this.creq = this.route.snapshot.queryParams['creq'];
    this.threeDSRef = this.route.snapshot.queryParams['threeDSRef'];

    console.warn(this.route.snapshot.queryParams);

      // get user address
      this.candy.createRequest('GET', 'api/v1/company').subscribe(response=> {
        if (response) {
          this.addresses = response['company'];
          this.userID = response['company']['user_id'];
        }
      })
      window.setTimeout(() => {
        this.form.nativeElement.submit();
      },1000)
  }

  onSubmit(e) {
    e.target.submit();
  }

}

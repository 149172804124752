import { Component, OnInit } from '@angular/core';
import { HeaderService } from '../global/header/header.service';
import { MessageService } from '../global/message/message.service';
import { MagentoService } from '../magento.service';
import { CandyService } from '../candy.service';

@Component({
  selector: 'app-contactus',
  templateUrl: './contactus.component.html',
  styleUrls: ['./contactus.component.scss']
})
export class ContactusComponent implements OnInit {

  name: string = null;
  companyName: string = null;
  contactNumber: string = null;
  email: string = null;
  message: string = null;

  formError: string = null;

  formSubmitted: boolean = false;

  constructor(private magento: MagentoService, private candy: CandyService, private messageService: MessageService, private header: HeaderService) { }

  ngOnInit() {
  }

  submitForm() {
    this.formError = null;
    if(!this.name || !this.email || !this.message) {
      this.formError = 'Please fill in the required fields';
      return;
    }

    this.header.setShowLoader(true);

    var data = {
      'form_data': {
        'name': this.name,
        'company': this.companyName,
        'contact_number': this.contactNumber,
        'email_address': this.email,
        'message': this.message
      }
    }

    // this.magento.createRequest('POST', 'V1/broadlanddigital/contact-form/send', data).subscribe(response => {
    this.candy.createRequest('POST', 'api/v1/contactform/send', data).subscribe(response => {
      this.header.setShowLoader(false);
      this.messageService.showMessage('Your message has been sent.', 'success');

      this.name = null;
      this.companyName = null;
      this.contactNumber = null;
      this.email = null;
      this.message = null;
    
      this.formSubmitted = true;
    }, error => {
      this.header.setShowLoader(false);
      this.formSubmitted = false;
      this.messageService.showMessage(error, 'error');
    })
  }
}

import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { HttpClient } from '@angular/common/http';

import * as jQuery from 'jquery';
import { HeaderService } from 'src/app/global/header/header.service';

import { getQueryPredicate } from '@angular/compiler/src/render3/view/util';
import { UtilService } from 'src/app/util.service';
import { MagentoService } from 'src/app/magento.service';
import { MessageService } from 'src/app/global/message/message.service';
import { CandyService } from 'src/app/candy.service';

declare const window: any;

@Component({
	selector: 'app-delivery',
	templateUrl: './delivery.component.html',
	styleUrls: ['../cart.component.scss', './delivery.component.scss', '../details/details.component.scss']
})
export class DeliveryComponent implements OnInit {

	cartContents: Array<any> = [];
	totalCheckout: number = null;
	selectedPostage: String = null;
	finalError: String = null;
	loggedInUser: Object = null;
	postageId: Number = null;

	qty: number = 0;

  products: Array<any> = [];
  product_count: Number = 0;
  total: number = 0;
  sub_total: number = 0;
  delivery: number = 0;

  sameAsShipping: boolean = true;

  deliveryOptionSelected: string = null;

  productsImages: Array<any> = [];
  payment_methods: Array<any> = [];
  // selected_payment_method: payment_method_interface;
  selected_payment_method_index: number;
  // address: address_interface;

  // selectedShipping: address_interface;
  // defaultShipping: address_interface;
  showUpdateDefaultShipping: boolean = false;
  customer: any;
  shipping_method: string = null;
  basket_id: String = null;
  token: String = null;
  discount: number = 0;

  delivery_methods: Array<any> = [];
  selected_address: Object;
  selected_delivery_method: Array<any> = [];
  selected_delivery_method_index: number;
  allow_continue: Boolean = false;
  vat: number = 0;

  deliverySelected: boolean = false;

	constructor(private http: HttpClient, private router: Router, private route: ActivatedRoute, private headerService: HeaderService,  private util: UtilService, private magento: MagentoService, private message: MessageService, private candy: CandyService) { }

	getQty() {

	}

  ngOnInit() {
    var cart = JSON.parse(localStorage.getItem('cart'));
    // this.customer = JSON.parse(this.util.getCookie('customer'));
    var url = null;

    this.headerService.setShowLoader(true);

    // get user address
    this.candy.createRequest('GET', 'api/v1/company').subscribe(response=> {
      console.warn(response);
      if (response) {
        this.selected_address = response;
      }
    })

    if (cart) {
      // this.payment_methods = cart['payment_methods'];
      // this.shipping_method = cart['shipping_method'];

      this.basket_id = cart['basket_id'];
      // this.defaultShipping = cart['address_information']['addressInformation']['shipping_address'];

      if (this.util.getCookie('customer_token')) {
        this.token = this.util.getCookie('customer_token');
      }

      if(this.basket_id){

        this.getDeliveryMethods(null);

        if(this.token){
          url = 'api/v1/baskets/current';
        }
        // GC API

        this.candy.createRequest('GET', url).subscribe(response => {
          console.warn(response);
          if(response){
            this.sub_total = response['data']['sub_total'];
            this.discount = response['data']['discount_total'];
            // this.delivery = response['shipping_amount'];
            // this.vat = response['base_tax_amount'] > 0 && response['grand_total'] > 5000 ? 0 : response['base_tax_amount'] - response['base_shipping_tax_amount'];
            this.vat = response['data']['tax_total'];
            // remove delivery tax from being shown at this point
            // this.total = response['base_subtotal_with_discount'] > 5000 ? response['base_subtotal_with_discount'] : response['base_subtotal_with_discount'] + response['base_tax_amount'] - response['base_shipping_tax_amount'];
            this.total = response['data']['total'];

            // get products, store in array

            this.products = response['data']['lines']['data'];

            var allProductsCellular = false; // Flag to check if all products are cellular

            this.products.forEach((product) => {
              product['img_url'] = 'loading';
              this.candy.getProductImagesWithCustomDetails(product['variant']['data']['product']['data']['model-id']).then((res) => {
                product['img_url'] = res['image'];
                product['custom_details'] = res['custom_details'];
                // Check if all products are cellular
                for (const product of this.products) {
                  if (
                    product['custom_details'] !== null &&
                    product['custom_details'] !== undefined && // Add this check
                    product['custom_details'].hasOwnProperty('is_cellular') &&
                    product['custom_details']['is_cellular']
                  ) {
                    allProductsCellular = true;
                    break;
                  }
                }
              }).catch(error => {
                console.log(error);
              });
            });

            this.product_count = this.products.length;
            var basketID = response['data']['id']
            var cart = {
              basket_id: basketID,
              item_count:this.product_count
            }

            var taxType = this.products[0]['variant']['data']['tax']['data']['name'];

            this.candy.createRequest('GET', 'api/v1/quotes/basket-price?basket_id='+basketID).subscribe(response => {
              if (response) {
                this.sub_total = 0;

                for (var i = 0; i < response.length; i++) {
                  this.products[i].unit_price = parseFloat(response[i].total) / response[i].quantity;
                  this.sub_total += parseFloat(response[i].total);
                  this.total = this.sub_total;
                }

                this.vat = 0;
                if (allProductsCellular && this.total >= 5000 && taxType === 'Standard') {
                  this.vat = 0;
                } else {
                  if (this.products[0]) { this.products[0].tax ? this.vat = this.total * 0.2 : this.vat = 0; }
                  if (this.total >= 5000) { this.vat = 0; } // remove tax if value minimum 5000, only 2 for delivery vat
                  if (this.total >= 5000 && taxType === 'Standard') { this.vat = 0; } // remove tax if value minimum 5000 & is standard vat
                }
                this.total += this.vat;
                this.headerService.setShowLoader(false);
              }
            }, error => {
              this.headerService.setShowLoader(false);
            });

            this.headerService.setShowLoader(false);
          }
        }, response => {
          this.headerService.setShowLoader(false);
          this.message.showMessage('There was an issue loading your basket', 'error');
        });

      } else {
        this.headerService.setShowLoader(false);
      }
    }
  }

  getDeliveryMethods(address = null) {

    // GC API
    // url = 'shipping';

    var url = 'api/modules/shipping/1';

    // this is for future if more delivery methods needed etc
    // var url2 = 'api/modules/shipping?dest_country_id=GB'


    this.candy.createRequest('GET', url).subscribe(response=>{
      console.warn(response);
      if (response) {

          this.delivery_methods.push(response[0]);

      // this.delivery_methods.push(response['data'][1])
      // this.delivery_methods.push(response['data'][2])
      // console.warn(this.delivery_methods);
      }
      console.warn(this.delivery_methods);
    })
    // // use shipping address
    // var addr = {
    //   "address": {
    //     "region": address.region.region,
    //     "region_id": address.region_id,
    //     "region_code": address.region.region_code,
    //     "country_id": address.country_id,
    //     "street": [
    //       address.street[0],
    //       address.street[1]
    //     ],
    //     "postcode": address.postcode,
    //     "city": address.city,
    //     "firstname": address.firstname,
    //     "lastname": address.lastname,
    //     "customer_id": address.customer_id,
    //     "email": null,
    //     "telephone": address.telephone,
    //     "same_as_billing": 1
    //   }
    // }

    // this.magento.createRequest('POST', url, addr, this.token).subscribe(response => {
    //   console.warn(response)
    //   //this.magento.createRequest('GET', url, null, null).subscribe(response => {
    //     if(response){
    //       this.delivery_methods = response;
    //       this.headerService.setShowLoader(false);
    //     } else {
    //       this.message.showMessage('Unable to select address', 'error');
    //       this.headerService.setShowLoader(false);
    //     }
    //   }, response => {
    //     this.message.showMessage('Unable to select address', 'error');
    //     this.headerService.setShowLoader(false);
    // });
  }

  getTotals () {
    var url = 'default/V1/carts/mine/totals';

    // GC API
    // url = 'baskets/current';

    this.magento.createRequest('GET', url, null, this.token).subscribe(response => {
      this.sub_total = response['subtotal'];
      this.discount = response['discount'];
      this.delivery = response['shipping_amount'];
      this.vat = response['base_tax_amount'] > 0 && response['grand_total'] > 5000 ? 0 : response['base_tax_amount'] - response['base_shipping_tax_amount'];
      this.total = response['base_subtotal_with_discount'] > 5000 ? response['base_subtotal_with_discount'] : response['base_subtotal_with_discount'] + response['base_tax_amount'] - response['base_shipping_tax_amount'];
      this.headerService.setShowLoader(false);
    })
  }

  getProductOptions(skus) {
    var data = {
      'data': skus
    }
    this.magento.createRequest('POST', 'V1/broadlanddigital/product/get-custom-attribute-values', data).subscribe(response => {
      var productOptions = response;
      productOptions.forEach(cartItem => {
        this.products.forEach(device => {
          if (Object.keys(cartItem)[0] == device.sku) {
            device['options'] = Object.values(cartItem)[0];
          }
        })
      });
      this.headerService.setShowLoader(false);
    })
  }

  selectMethod(method, i){
    this.selected_delivery_method = method;
    this.selected_delivery_method_index = i;
    console.warn(method);

    // this.getTotals();
    // this.headerService.setShowLoader(true);
    this.deliverySelected = true;
    this.delivery = Number(method['price']);
    console.log(this.delivery);
    // fixed 9.99 delivery has VAT of 2..
    this.vat += 2.00;
    this.total += this.delivery + 2.00;

    var cart = JSON.parse(localStorage.getItem('cart'));
    cart['address_information'] = method;
    cart['total'] = this.total;
    localStorage.setItem('cart', JSON.stringify(cart));
    console.warn(cart);

    // GC API
    // url = 'baskets/current';

    this.allow_continue = true;
  }

	continue() {
    var url = null;

    if(this.allow_continue == true){
      this.router.navigateByUrl('/cart/payment');
    }
	}

	recalculateTotals(){
		this.cartContents = JSON.parse(localStorage.getItem("cart"));

		this.totalCheckout = 0;

		for(let item of this.cartContents){
			this.totalCheckout = this.totalCheckout + (item.quantity * item.price);
		}
	}

}

import { Component, OnInit } from '@angular/core';
import { MagentoService } from 'src/app/magento.service';
import { ActivatedRoute, Router } from '@angular/router';
import { HeaderService } from 'src/app/global/header/header.service';
import { UtilService } from 'src/app/util.service';
import { SessionService } from 'src/app/session.service';
import { OrderSelected } from '../order-selected.service';
import { CandyService } from 'src/app/candy.service';
import { MessageService } from 'src/app/global/message/message.service';

export interface ICustomer {
  name: string;
  addresses: Array<any>;
}

@Component({
  selector: 'app-quotes',
  templateUrl: './quotes.component.html',
  styleUrls: ['./quotes.component.scss', '../account.component.scss']
})


export class QuotesComponent implements OnInit {

  quotes: Array<any> = [];

  quoteSelected: boolean = false;

  quote: any;
  timer: any;
  customer: ICustomer;

  listItems: any[] = [
    {'name': 'My Account', 'routerLink': '/account'},
    {'name': 'My Orders', 'routerLink':'/account/orders'},
    {'name': 'My Quotes', 'routerLink':'/account/quotes'},
  ];
  selectedLi: object = this.listItems[2];

  allSKUs: Array<any> = [];
  products: Array<any> = [];

  deliveryMethod: string = null;

  constructor(private magento: MagentoService, private router: Router, private header: HeaderService, private util: UtilService, private session: SessionService, private _route: ActivatedRoute, private candy: CandyService, private message: MessageService) {

  }

  ngOnInit() {
    if(this.session.isLoggedIn()){

      this.header.setShowLoader(true);

      this.candy.createRequest('GET', 'api/v1/quotes/user').subscribe(response => {
        this.quotes = response;
        this.getTimeRemaining();
        clearTimeout(this.timer);
        this.timer = window.setInterval(() => {
          this.getTimeRemaining();
        }, 1000)
        for(var quote of this.quotes) {
          quote['total'] = 0;
          for(var item of quote['items']) {
            quote['total'] += item.discount_total;
          }
        }
        this.header.setShowLoader(false);
      }, error => {
        if(error['status'] === 503) {
          this.message.showMessage('Prices are currently being updated. Please try again later.', 'error');
          this.candy.customerLogout();
        } else {
          this.message.showMessage('Error getting quotes', 'error');
        }
        this.header.setShowLoader(false);
      })

      this.customer = {
        name: '',
        addresses: []
      }

    } else {
      this.router.navigateByUrl('/login')
    }

    if (OrderSelected.currentQuote) {
      this.quoteSelected = true;
      this.quote = OrderSelected.currentQuote;
      this.deliveryMethod = OrderSelected.currentQuote['shipping_description'].replace(/[^-]*$/, "").replace(" -", "");
    }
  }

  selectListItem(item) {
    this.selectedLi = item;
    this.quoteSelected = false;
  }

  selectQuote(quote) {
    this.quoteSelected = true;
    this.quote=quote;
  }

  convertQuote(){    
    this.candy.createRequest('GET', 'api/v1/quotes/convert?quote_id='+this.quote['id']+'&is_dashboard=true').subscribe(response => {
      //add items to cart
      this.quote.status = response['status'];
      this.quote.expiry = response['expiry'];
      this.router.navigateByUrl('/cart');
      this.header.setShowLoader(false);
    }, error => {
      if(error['status'] === 503) {
        this.message.showMessage('Prices are currently being updated. Please try again later.', 'error');
        this.candy.customerLogout();
      } else {
        this.message.showMessage('Error accepting quote', 'error');
      }
      this.header.setShowLoader(false);
    })
  }

  getTimeRemaining() {
    for(var quote of this.quotes) {
      var now: any = new Date();
      var expiryDate: any = new Date(quote['expiry']);
      quote['timeRemaining'] = new Date(expiryDate - now);
  
      quote['timeRemaining']['days'] = Math.floor(quote['timeRemaining'] / (1000 * 60 * 60 * 24));
      quote['timeRemaining']['hours'] = Math.floor((quote['timeRemaining'] % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
      quote['timeRemaining']['minutes'] = Math.floor((quote['timeRemaining'] % (1000 * 60 * 60)) / (1000 * 60));
      quote['timeRemaining']['seconds'] = Math.floor((quote['timeRemaining'] % (1000 * 60)) / 1000);      
    
      if(quote['timeRemaining']['hours'] < 0 && quote['timeRemaining']['minutes'] < 0 && quote['timeRemaining']['seconds'] < 0) {
        quote['timeRemaining']['hours'] = 0
        quote['timeRemaining']['minutes'] = 0
        quote['timeRemaining']['seconds'] = 0
      }
    }
  }

  logOut() {
    this.candy.customerLogout();
  }
}

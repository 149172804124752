import { Pipe , PipeTransform  } from "@angular/core";

@Pipe({name: 'sortPipe'})

export class SortPipe implements PipeTransform {

    transform(array: Array<string>): Array<string> {
        array = array.sort((a: any, b: any) => {
                // handle memory options
                if (a.indexOf('GB') > -1) {
                    return  Number(a.replace('GB', '')) - (Number(b.replace('GB', '')))
                } else {
                    return a.replace('GB', '').localeCompare(b.replace('GB', ''))
                }
            }
        )
        return array;
  }
}
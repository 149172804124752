import { Component } from '@angular/core';
import {Router, NavigationEnd} from '@angular/router'; // import Router and NavigationEnd

@Component({
  selector: 'app-root',
  templateUrl: './wrapper.component.html',
  styleUrls: ['./wrapper.component.scss']
})
export class SiteWrapper {
  constructor(public router: Router) {

  }
}

import { Component, EventEmitter, OnInit, Output, ViewChild } from '@angular/core';
declare var Worldpay: any;

@Component({
  selector: 'app-worldpay',
  templateUrl: './worldpay.component.html',
  styles: []
})

export class WorldpayComponent implements OnInit {

  @ViewChild('paymentForm', {static: false}) form;
  // Live Key
  readonly worldpayClientKey = 'L_C_53215163-94ff-42a0-9f11-dac14f7e7888';
  // Dev Key
  // readonly worldpayClientKey = 'T_C_6a293346-8517-49ff-9c72-0430d7551d72';
  token: string = null;

  @Output() passToken: EventEmitter<any> = new EventEmitter();
  @Output() submitFormEvent: EventEmitter<any> = new EventEmitter();

  constructor() {
  }

  ngOnInit() {
    this.loadScript('https://cdn.worldpay.com/v1/worldpay.js', this.init);
  }

  init = (): void => {
    Worldpay.useTemplateForm({
      'clientKey': this.worldpayClientKey,
      'form': this.form.nativeElement,
      'paymentSection': 'paymentSection',
      'display': 'inline',
      'type': 'card',
      'callback': this.worldpayCallback
    });
  }

  worldpayCallback = (status): void => {
    this.token = status.token;
    this.passToken.emit(this.token);
  }

  private loadScript(url: string, callbackFunction: (any) = undefined) {
    const node = document.createElement('script');
    node.src = url;
    node.type = 'text/javascript';
    node.onload = callbackFunction;
    document.getElementsByTagName('body')[0].appendChild(node);
  }

  submitForm(){
    Worldpay.submitTemplateForm({
      'clientKey': this.worldpayClientKey,
      'form': this.form.nativeElement,
      'paymentSection': 'paymentSection',
      'display': 'inline',
      'type': 'card',
      'callback': this.worldpayCallback
    });
    setTimeout(() => this.submitFormEvent.emit(true), 1000)

  }
}

import { Component, OnInit } from '@angular/core';
import { MagentoService } from '../magento.service';
import { Router } from '@angular/router';
import { HeaderService } from '../global/header/header.service';
import { UtilService } from '../util.service';
import { SessionService } from '../session.service';
import { PopupComponent } from '../global/popup/popup.component';
import { MessageService } from '../global/message/message.service';
// import { address_interface } from '../checkout/review/review.component';
import { OrderSelected } from './order-selected.service';
import { FooterService } from '../global/footer/footer.service';
import { CandyService } from '../candy.service';

export interface customerInterface{
  name: String;
  email: String;
  // address: string;
}

export interface payment_method_interface {
  code: String;
  title: String;
}

export interface address_interface{
  firstname: String;
  lastname: String;
  street: [{},{}];
  city: String;
  postcode: String;
  telephone: String;
  contact_name: string;
  address: string;
  address_two: string;
  town: string;
}

export interface extension_attributes{
  is_subscribed: Boolean;
}

@Component({
  selector: 'app-account',
  templateUrl: './account.component.html',
  styleUrls: ['./account.component.scss']
})
export class AccountComponent implements OnInit {

  customer: customerInterface;
  customerToken: string;
  orders: Array<any> = [];

  listItems: any[] = [
    {'name': 'My Account', 'routerLink': '/account'},
    {'name': 'My Orders', 'routerLink':'/account/orders'},
    {'name': 'My Quotes', 'routerLink':'/account/quotes'},
  ];
  selectedLi: object = this.listItems[0];

  defaultBilling: address_interface;
  defaultShipping: address_interface;
  defaultShippingIndex: number = null;

  showPasswordReset: Boolean = false;
  currentPassword: String = null;
  newPassword: String = null;
  newPasswordConfirm: String = null;

  showEditUserDetails: Boolean = false;
  firstName: String = null;
  lastName: String = null;
  name: String = null;

  addresses: Array<any> = [];

  showUpdateDefaultBilling: Boolean = false;
  showUpdateDefaultShipping: Boolean = false;

  showCreateBilling: Boolean = false;
  showCreateShipping: Boolean = false;

  newAddressFirstName: string = null;
  newAddressLastName: string = null;
  newAddressLine1: string = null;
  newAddressLine2: string = null;
  newAddressCity: string = null;
  newAddressCounty: string = null;
  newAddressPostcode: string = null;
  newAddressTelephone: string = null;


  selectedBilling: address_interface;
  selectedShipping: address_interface;

  token: string = null;

  constructor(private magento: MagentoService, private router: Router, private header: HeaderService, private util: UtilService, private session: SessionService, private popup: PopupComponent, private message: MessageService, private footer: FooterService, private candy: CandyService) { }

  ngOnInit() {
    this.header.setShowHeader(true)
    this.footer.setShowFooter(true)

    if(this.session.isLoggedIn()){
      this.header.setShowLoader(true);

      this.customerToken = this.util.getCookie('customer_token');

      // get user address
      this.candy.createRequest('GET', 'api/v1/company').subscribe(response=> {
        console.warn(response);
        if (response) {
          var address = response['company'];
          this.defaultBilling = this.defaultShipping = address;
          this.customer = {
            'name': address['contact_name'],
            'email': address['email'],
          };
        }
      })

      if(this.customerToken){

        //get the default billing and shipping
        for (let i = 0; i < this.addresses.length; i++) {
          if(this.addresses[i]['id'] == this.customer['default_billing']){
            this.defaultBilling = this.addresses[i];
          }

          if(this.addresses[i]['id'] == this.customer['default_shipping']){
            this.defaultShipping = this.addresses[i];
          }
        }
        this.header.setShowLoader(false);
        // GC API
        // url = 'orders';

        // this.candy.createRequest('GET', 'V1/orders/').subscribe(response => {
        //   if(response['items'].length >= 1){
        //     this.orders = response['items'];
        //     console.warn(this.orders);

        //   }
        //   this.header.setShowLoader(false);
        // });
      } else {
        this.router.navigateByUrl('/login')
      }
    } else {
      this.router.navigateByUrl('/login')
    }
  }

  selectListItem(item) {
    this.selectedLi = item;
  }

  logOut() {
    this.candy.customerLogout();
  }

  openPasswordReset(){
    this.showPasswordReset = true;
  }

  updatePassword(){
    if(this.currentPassword && this.newPassword && this.newPasswordConfirm){
      var customer = JSON.parse(this.util.getCookie('customer'));
      if(customer && customer['id']){

        if(this.newPassword === this.newPasswordConfirm){
          this.header.setShowLoader(true);
          var data = {
            "currentPassword": this.currentPassword,
            "newPassword": this.newPassword,
            // needed for GC
            "email": customer['email'],
            "password": this.newPassword,
            "password_confirmation": this.newPassword
          }

          // GC API
          // http://localhost:8000/api/v1/users/ + customer['id']

          this.candy.createRequest("PUT", "users/" + customer['id'], data).subscribe(response => {
            console.log(response);
          })

          this.magento.createRequest('PUT', 'V1/customers/me/password?customerId='+customer['id'], data).subscribe(response => {
            this.showPasswordReset = false;
            this.message.showMessage('Your password has been updated.', 'success', true);
            this.header.setShowLoader(false);
          }, response => {
            this.header.setShowLoader(false);
            this.message.showMessage(response['error']['message'], 'error');
          });
        } else {
          this.message.showMessage('Your passwords do not match.', 'error');
        }
      } else {
        this.message.showMessage('Customer session does not exist', 'error');
      }
    } else {
      this.message.showMessage('Please fill in all the fields', 'error');
    }
  }

  openEditUserDetails(){
    this.showEditUserDetails = true;
  }

  closePopup(){
    this.showEditUserDetails = false;
    this.showCreateBilling = false;
    this.showCreateShipping = false;
    this.showPasswordReset = false;
    this.showUpdateDefaultBilling = false;
    this.showUpdateDefaultShipping = false;
  }

  updateUserDetails(){
    var customer = JSON.parse(this.util.getCookie('customer'));
    if (this.util.getCookie('customer_token')) {
      this.token = this.util.getCookie('customer_token');
    }
    var data = {
      "customer": {}
    };

    if(customer && customer['id']){
      if(this.firstName != customer['firstname'] || this.lastName != customer['lastname']){
        data['customer']['id'] = customer['id'];
        data['customer']['email'] = customer['email'];
        data['customer']['website_id'] = 1;
        data['customer']['firstname'] = this.firstName;
        data['customer']['lastname'] = this.lastName;

        if(data != null){
          this.header.setShowLoader(true);

          this.candy.createRequest("PUT", "users/" + customer['id'], data).subscribe(response => {
            console.log(response);
          })

          this.magento.createRequest('PUT', 'V1/customers/me', data, this.token).subscribe(response => {
            this.showEditUserDetails = false;
            this.message.showMessage('Your customer details have been updated.', 'success', true);
            this.customer = response;
            this.util.setCookie('customer', JSON.stringify(response), 1); //4 = 4 hours to match magento's token timeout
            this.header.setShowLoader(false);
          }, response => {
            this.header.setShowLoader(false);
            this.message.showMessage(response['error']['message'], 'error');
          });
        } else {
          this.message.showMessage('No changes have been made', 'information');
        }
      } else {
        this.message.showMessage('No changes have been made', 'information');
      }
    } else {
      this.message.showMessage('Customer session does not exist', 'error');
    }
  }

  openUpdateDefaultBilling(){
    this.showUpdateDefaultBilling = true;
  }

  selectBilling(address){
    this.selectedBilling = address;
  }

  saveDefaultBilling(){
    if (this.selectedBilling) {

      this.defaultBilling = this.selectedBilling;

      var customer = JSON.parse(this.util.getCookie('customer'));
      if (this.util.getCookie('customer_token')) {
      this.token = this.util.getCookie('customer_token');
      }


      customer["default_billing"] = this.selectedBilling["id"].toString();

      customer["addresses"].forEach(address => {
        if (address.id == customer["default_billing"] ) {
          address["default_billing"] = true;
        } else {
          delete address["default_billing"]
        }
      } )


      var data = {
        "customer": {
            "id": customer["id"],
            "email": customer["email"],
            "firstname": customer["firstname"],
            "lastname": customer["lastname"],
            "default_billing":  customer["default_billing"],
            "website_id": 1,
            "addresses": customer["addresses"]
        }
      };


      if (data!=null) {
        this.header.setShowLoader(true);

        this.magento.createRequest('PUT', 'V1/customers/me', data, this.token ).subscribe(response => {
        this.header.setShowLoader(false);
        this.showUpdateDefaultBilling = false;
        })
      }
    } else {
      this.showUpdateDefaultBilling = false;
    }
}

  openCreateNewBilling(){
    this.showCreateBilling = true;
  }


  openUpdateDefaultShipping(){
    this.showUpdateDefaultShipping = true;
  }

  selectShipping(address){
    this.selectedShipping = address;
  }

  deleteAddress() {
    if (this.selectedBilling || this.selectedShipping) {
      if (this.selectedBilling) {
      var addressToDelete = this.selectedBilling;
      } else {
        var addressToDelete = this.selectedShipping;
      }

      var customer = JSON.parse(this.util.getCookie('customer'));
      this.token = this.util.getCookie('customer_token');


      customer["addresses"].forEach(address => {
        if (address['id'] == addressToDelete['id']) {
          const index = customer["addresses"].indexOf(address);

          if (index > -1) {
            customer["addresses"].splice(index, 1);
          }
        }
      })

      var data = {
        "customer": {
            "id": customer["id"],
            "email": customer["email"],
            "firstname": customer["firstname"],
            "lastname": customer["lastname"],
            "default_billing":  customer["default_billing"],
            "default_shipping": customer["default_shipping"],
            "website_id": 1,
            "addresses": customer["addresses"]
        }
      };

      if (data!=null) {
        this.header.setShowLoader(true);

        this.magento.createRequest('PUT', 'V1/customers/me', data, this.token ).subscribe(response => {
        this.header.setShowLoader(false);
        this.message.showMessage('Address has been deleted.', 'success', true);
        this.util.setCookie('customer', JSON.stringify(response), 1); //4 = 4 hours to match magento's token timeout

        this.customer = response;
        this.addresses = this.customer['addresses'];

        this.showCreateBilling = false;
        this.showCreateShipping = false;
        })
      }




    }  else {
      this.message.showMessage('Please select an address to delete', 'error');
    }
  }

  saveDefaultShipping() {
    if (this.selectedShipping) {
      this.defaultShipping = this.selectedShipping;

      var customer = JSON.parse(this.util.getCookie('customer'));
      this.token = this.util.getCookie('customer_token');

      customer["default_shipping"] = this.selectedShipping["id"].toString();

      customer["addresses"].forEach(address => {
        if (address.id == customer["default_shipping"] ) {
          address["default_shipping"] = true;
        } else {
          delete address["default_shipping"]
        }
      } )

      var data = {
        "customer": {
            "id": customer["id"],
            "email": customer["email"],
            "firstname": customer["firstname"],
            "lastname": customer["lastname"],
            "default_billing":  customer["default_billing"],
            "default_shipping": customer["default_shipping"],
            "website_id": 1,
            "addresses": customer["addresses"]
        }
      };

      if (data!=null) {
        this.header.setShowLoader(true);

        this.magento.createRequest('PUT', 'V1/customers/me', data, this.token ).subscribe(response => {
        this.header.setShowLoader(false);
        this.message.showMessage('Your address details have been updated.', 'success', true);
        this.util.setCookie('customer', JSON.stringify(response), 1); //4 = 4 hours to match magento's token timeout


        this.customer = response;
        this.addresses = this.customer['addresses'];

        this.showUpdateDefaultShipping = false;
        })
      }
    } else {
      this.showUpdateDefaultShipping = false;
    }
  }

  openCreateNewShipping(){
    this.showCreateShipping = true;
  }

  saveNewAddress(type){
    var customer = JSON.parse(this.util.getCookie('customer'));
    this.token = this.util.getCookie('customer_token');
    this.defaultShippingIndex = null;

    customer["addresses"].forEach(address => {
      if (address['default_shipping'] == true) {
        this.defaultShippingIndex =  customer["addresses"].indexOf(address);
      }
    });

    var data = {
      "customer": {
        "id": customer["id"],
        "email": customer["email"],
        "firstname": customer["firstname"],
        "lastname": customer["lastname"],

        "website_id": 1,
        "addresses" : customer['addresses']
    }
    };

    if(customer && customer['id']){
      var newAddress = data.customer.addresses[this.defaultShippingIndex];

      if (!this.newAddressFirstName || !this.newAddressLastName || !this.newAddressLine1 || !this.newAddressPostcode || !this.newAddressTelephone  ) {
        this.message.showMessage('Please ensure all address details have been given', 'error')
        return false;
      }

      newAddress["firstname"] = this.newAddressFirstName;
      newAddress["lastname"] = this.newAddressLastName;
      newAddress["street"][0] = this.newAddressLine1;
      if(this.newAddressLine2) {
        newAddress["street"][1] = this.newAddressLine2
      }
      newAddress["city"] = this.newAddressCity;
      newAddress["postcode"] = this.newAddressPostcode;
      newAddress["telephone"] = this.newAddressTelephone;

      data.customer.addresses[this.defaultShippingIndex] = newAddress


      if (newAddress!=null) {
        this.header.setShowLoader(true);

        this.magento.createRequest('PUT', 'V1/customers/me', data, this.token ).subscribe(response => {

        console.warn(response);

        this.header.setShowLoader(false);

        this.message.showMessage('Your customer details have been updated.', 'success', true);

        this.util.setCookie('customer', JSON.stringify(response), 1); //4 = 4 hours to match magento's token timeout

        this.customer = response;
        this.addresses = this.customer['addresses'];
        //get the default billing and shipping
        for (let i = 0; i < this.addresses.length; i++) {
          if(this.addresses[i]['id'] == this.customer['default_billing']){
            this.defaultBilling = this.addresses[i];
          }

          if(this.addresses[i]['id'] == this.customer['default_shipping']){
            this.defaultShipping = this.addresses[i];
          }
        }
        this.showCreateBilling = false;
        this.showCreateShipping = false;
      },response => {
        this.header.setShowLoader(false);
        this.message.showMessage(response['error']['message'], 'error');
        }
      )
    } else {
      this.message.showMessage('No changes have been made', 'information');
    }

    }

  }

  selectOrder(order) {
    OrderSelected.currentOrder = order;
    this.router.navigateByUrl('/account/orders');
  }

  reorder(order){
    var token = null;
    this.header.setShowLoader(true);

    if(!localStorage.getItem('cart')){
      token = this.util.getCookie('customer_token');

      this.magento.createRequest('POST', 'default/V1/carts/mine', '', token).subscribe(response => {
        if(response){
          var basket_id = response;
          var data = {'data': {
            'orderId': order['entity_id'],
            'cartId': basket_id
          }};
          this.magento.createRequest('POST', 'V1/broadlanddigital/customer/order/reorder', data).subscribe(response => {
            this.magento.createRequest('GET', 'V1/broadlanddigital/cart/getproducts/'+basket_id).subscribe(response => {
              if(response){
                localStorage.setItem('cart', JSON.stringify({basket_id: basket_id, item_count: 1}));
                this.message.showMessage('The items have been added to your basket', 'success');
                this.header.setShowLoader(false);
              } else {
                this.message.showMessage('There was an issue loading your basket', 'error');
                this.header.setShowLoader(false);
              }
            }, response => {
              this.message.showMessage('There was an issue loading your basket', 'error');
              this.header.setShowLoader(false);
            });

          }, response => {
            this.message.showMessage(response, 'error');
          });
        }
      }, response => {
        this.message.showMessage(response, 'error');
      });
    } else {
      var basket_id = null;
      basket_id = JSON.parse(localStorage.getItem('cart'))['basket_id'];

      var data = {'data': {
        'orderId': order['entity_id'],
        'cartId': basket_id
      }};


      this.magento.createRequest('POST', 'V1/broadlanddigital/customer/order/reorder', data).subscribe(response => {
        this.magento.createRequest('GET', 'V1/broadlanddigital/cart/getproducts/'+basket_id).subscribe(response => {
          if(response){
            localStorage.setItem('cart', JSON.stringify({basket_id: basket_id, item_count: 1}));
            this.message.showMessage('The items have been added to your basket', 'success');
            this.header.setShowLoader(false);
          } else {
            this.message.showMessage('There was an issue loading your basket', 'error');
            this.header.setShowLoader(false);
          }
        }, response => {
          this.message.showMessage('There was an issue loading your basket', 'error');
          this.header.setShowLoader(false);
        });

      }, response => {
        this.message.showMessage(response, 'error');
      });
    }
  }

}


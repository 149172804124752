import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs/internal/BehaviorSubject';

@Injectable()
export class HeaderService {

    public showHeader = new BehaviorSubject('ShowHeader');
    public showPasswordReset = new BehaviorSubject('ShowPasswordReset');
    public showCartDot = new BehaviorSubject('ShowCartDot');
    public showLoader = new BehaviorSubject('ShowLoader');
    public showMessage = new BehaviorSubject('ShowMessage');

    constructor() { }

    setShowHeader(showHeader){
        this.showHeader.next(showHeader);
    }

    setShowPasswordReset(showPasswordReset){
        this.showPasswordReset.next(showPasswordReset);
    }

    setShowCartDot(showCartDot) {
        this.showCartDot.next(showCartDot);
    }

    setShowLoader(showLoader){
      this.showLoader.next(showLoader);
    }

    setShowMessage(showMessage) {
      this.showMessage.next(showMessage);
    }
}

import { Injectable, ChangeDetectorRef } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import {mergeMap} from 'rxjs/operators';
import { from } from 'rxjs';
// import { MessageService } from './global/message/message.service';
import { HeaderService } from './global/header/header.service';
import { UtilService } from './util.service';
import { SessionService } from './session.service';
import { Router } from '@angular/router';
import { MessageService } from './global/message/message.service';
// import { faTreeChristmas } from '@fortawesome/pro-light-svg-icons';

@Injectable({
  providedIn: 'root'
})
export class MagentoService {

  // public hostname: String = 'https://dev-api.ingrammicrodealerportal.co.uk/';
  // public api_host: String = 'https://dev-api.ingrammicrodealerportal.co.uk/rest/';

  public hostname: String = 'https://clstradesalesapi.ingrammicro.com/';
  public api_host: String = 'https://clstradesalesapi.ingrammicro.com/rest/';
  //REMOVED AUTH AS A TEST
  public candyhostname: String = 'getcandy.devicetradein.co.uk/api/v1/';

  constructor(private http: HttpClient, private util: UtilService, private headerService: HeaderService, private router: Router, private messageService: MessageService) {
  }

  createRequest(type = 'GET', url, payload = null, token = null): Observable<any>{
    return from(this.getToken()).pipe(mergeMap(response => {
      if(token == null){
        return this.getData(type, url, payload);
      } else {
        return this.getData(type, url, payload, token);
      }
    }));
  }

  getToken(){
    var promise = new Promise((resolve, reject) => {
      if(!this.util.getCookie("api_token")){
        var options = {}
        this.http.post(this.api_host+'V1/integration/admin/token', options, {headers: {'Content-Type': 'application/json; charset=utf-8'}, observe: 'response'}).subscribe(response =>{
          if(response.status == 200){
              this.util.setCookie('api_token', response.body, 4); //4 = 4 hours to match magento's token timeout
            resolve();
          } else {
            reject();
          }
        });
      } else {
        resolve();
      }
    });

    return promise;

  }

  getData(type = 'GET', url, payload = null, token = this.util.getCookie("api_token")){
    if(type == 'GET'){
      return this.http.get(this.api_host+url, {headers: {'Authorization': 'Bearer '+token}});
    } else if(type == 'POST') {
      return this.http.post(this.api_host+url, payload, {headers: {'Content-Type': 'application/json; charset=utf-8', 'Authorization': 'Bearer '+token}});
    } else if (type == 'PUT'){
      return this.http.put(this.api_host+url, payload, {headers: {'Content-Type': 'application/json; charset=utf-8', 'Authorization': 'Bearer '+token}});
    } else if (type == 'DELETE'){
      return this.http.delete(this.api_host+url, {headers: {'Content-Type': 'application/json; charset=utf-8', 'Authorization': 'Bearer '+token}});
    } else {
      return null;
    }
  }

  getCustomerToken(username, password){
    var promise = new Promise((resolve, reject) => {
      var options = {}
      this.http.post(this.api_host+'V1/integration/customer/token', options, {headers: {'Content-Type': 'application/json; charset=utf-8'}, observe: 'response'}).subscribe(response =>{
        if(response.status == 200){
          this.util.setCookie('customer_token', response.body, 1); //4 = 4 hours to match magento's token timeout
          resolve();
        } else {
          reject('Incorrect username or password');
        }
      }, response => {
        reject('Incorrect username or password');
      });
    });

    return promise;
  }

  customerLogin(username, password){
    return from(this.getCustomerToken(username, password)).pipe(mergeMap(token => {
      var promise = new Promise((resolve, reject) => {
        this.createRequest('GET', 'V1/customers/me', null, this.util.getCookie("customer_token")).subscribe(response => {
          if(response['id']){
            this.util.setCookie('customer', JSON.stringify(response), 1); //4 = 4 hours to match magento's token timeout
            this.createRequest('GET', 'V1/carts/mine', null, this.util.getCookie("customer_token")).subscribe(response => {
              if(response){
                localStorage.setItem('cart', JSON.stringify({quote_id: response['id'], item_count: response['items_count']}));
              }
            }, response => {
              reject(response);
            });
            resolve(JSON.stringify(response));
          }
        }, response => {
            reject('Incorrect username or password');
        });
      });

      return promise;
    }));
  }

  customerLogout(){
    // this.headerService.setShowLoader(true);
    localStorage.clear();
    this.util.deleteCookie('customer');
    this.util.deleteCookie('customer_token');
    this.router.navigateByUrl('/login');
    // this.headerService.setShowCartVI(false);
    // this.messageService.showMessage('You have been logged out.', 'success', true);
    // this.headerService.setShowLoader(false);
  }

  addToCart(product){
    var promise = new Promise((resolve, reject) => {
      var token = null;
      var url = null;

      if(this.util.getCookie('customer_token')){
        token = this.util.getCookie('customer_token');
      }

      //Cart doesnt exist, lets create the cart
      if(!localStorage.getItem('cart')){


        if(token != null){
          url = 'default/V1/carts/mine';
        } else {
          url = 'default/V1/guest-carts/';
        }

        this.createRequest('POST', url, '', token).subscribe(response => {
          if(response){
            var quote_id = response;
            localStorage.setItem('cart', JSON.stringify({quote_id: quote_id, item_count: 1}));

            var item;

            if(product.type_id == "virtual" || product.type_id == 'simple'){
              item = {
                  "cartItem": {
                    "sku": product.selected_sku ? product.selected_sku : product.sku,
                    "qty": product.selected_qty,
                    "quote_id": quote_id,
                    "price": product.price,
                    "productOption": product.productOption
                  }
              }
            } else if(product.type_id == "configurable") {
              reject('Please select a product option.');
            }

            if(item != null){
              if(token != null){
                url = 'default/V1/carts/mine/items';
                // url = 'V1/metpro/addtocart';
              } else {
                url = 'default/V1/guest-carts/'+quote_id+'/items';
              }

              this.createRequest('POST', url, item, token).subscribe(response => {
                if(response){
                  resolve();
                } else {
                  reject(response);
                }
              }, response => {
                reject(response['error']['message']);
              });
            } else {
              reject('An error occurred while adding to cart.');
            }
          } else {
            reject('An error occurred while creating the cart session.');
          }
        });

      } else {

        //Cart exists
        var cart = JSON.parse(localStorage.getItem('cart'));
        var quote_id = cart['quote_id'];

        var item;


        //TODO
        //Check to see if the product is already in the cart and needs to be updated
        if(product.type_id == "virtual" || product.type_id == 'simple'){
          item = {
              "cartItem": {
                "sku": product.selected_sku ? product.selected_sku : product.sku,
                "qty": product.selected_qty,
                "quote_id": quote_id,
                "price": product.price,
                "productOption": product.productOption
              }
          }
        } else if(product.type_id == "configurable") {
          reject('Please select a product option.');
        }

        //before we add to cart, lets check to make sure the customer is not mixing standard and non standard products
        this.createRequest('GET', 'default/V1/carts/mine', null, token).subscribe(response => {
          if(response['items']){
            var items = response['items'];
            var vat_type = null;

            items.forEach(element => {
              if(!vat_type){
                var sku = element.sku;
                sku = sku.split("_").pop();
                vat_type = sku;
                return false;
              }
            });

            if(vat_type){
              var prod_vat_type = item['cartItem']['sku'].split("_").pop();
              if(vat_type == prod_vat_type){
                if(item != null){
                  if(token != null){
                    url = 'V1/carts/mine/items';
                  } else {
                    url = 'default/V1/guest-carts/'+quote_id+'/items';
                  }

                  this.createRequest('POST', url, item, token).subscribe(response => {
                    if(response){
                      resolve();
                      // Add 1 to item_count in cart
                      localStorage.setItem('cart', JSON.stringify({quote_id: quote_id, item_count: cart.item_count+1}));
                    } else {
                      reject(response);
                    }
                  }, response => {
                    reject(response['error']['message']);
                  });
                } else {
                  reject('An error occurred while adding to cart.');
                }
              } else {
                reject('You cannot mix Marginal and Standard VAT products.');
              }
            } else {
              if(token != null){
                url = 'V1/carts/mine/items';
              } else {
                url = 'default/V1/guest-carts/'+quote_id+'/items';
              }

              this.createRequest('POST', url, item, token).subscribe(response => {
                if(response){
                  resolve();
                  // Add 1 to item_count in cart
                  localStorage.setItem('cart', JSON.stringify({quote_id: quote_id, item_count: cart.item_count+1}));
                } else {
                  reject(response);
                }
              }, response => {
                reject(response['error']['message']);
              });
            }
          }
        }, response => {
          reject(response);
        });
      }
    });

    return promise;
  }

  removeFromCart(product){
    this.headerService.setShowLoader(true);
    var token = null;
    var url = null;

    var cart = JSON.parse(localStorage.getItem('cart'));
    var quote_id = cart['quote_id'];

    localStorage.setItem('cart', JSON.stringify({quote_id: quote_id, item_count: cart.item_count-1}));

    var promise = new Promise((resolve, reject) => {

      if(this.util.getCookie('customer_token')){
        token = this.util.getCookie('customer_token');
      }

      if(token != null){
        url = 'V1/carts/mine/items/'+product['id'];
      } else {
        url = 'V1/guest-carts/items/'+product['id'];
      }

      this.createRequest('DELETE', url, '', token).subscribe(response => {
        this.headerService.setShowLoader(false);
        resolve(product['name']+'has been removed from your basket');
        var itemCount = JSON.parse(localStorage.getItem('cart'))['item_count'];

        if (itemCount <=0 ) {
          this.headerService.setShowCartDot(false);
        }

      }, response => {
        this.headerService.setShowLoader(false);
        reject(response);
      });
    });

    return promise;
  }

  updateCart(product){
    this.headerService.setShowLoader(true);
    var token = null;
    var url = null;

    var cart = JSON.parse(localStorage.getItem('cart'));
    var quote_id = cart['quote_id'];

    var item;

    if(product['qty'] >= 1){
      var promise = new Promise((resolve, reject) => {

        if(this.util.getCookie('customer_token')){
          token = this.util.getCookie('customer_token');
        }

        if(token != null){
          url = 'V1/carts/mine/items/'+product['id'];
        } else {
          url = 'V1/guest-carts/items/'+product['id'];
        }

        item = {
          "cartItem": {
            "sku": product.sku,
            "qty": product.qty,
            "quote_id": quote_id
          }
        }

        this.createRequest('PUT', url, item, token).subscribe(response => {
          this.headerService.setShowLoader(false);
          resolve(product['Name']+'has been been updated.');
        }, response => {
          this.headerService.setShowLoader(false);
          reject(response);
        });
      });
    } else {
      this.removeFromCart(product).then(response => {
        // this.messageService.showMessage(product['Name']+' Has been removed from the cart', 'success');
      }, response => {
        // this.messageService.showMessage(response, 'error');
      });
    }


    return promise;
  }


  getProductImageUrl(product){
    if(product['custom_attributes'] && product['custom_attributes'].find(array => array.attribute_code === 'image')){
      return this.hostname+'/media/catalog/product'+product['custom_attributes'].find(array => array.attribute_code === 'image')['value'];
    } else if(product['image']){
      return this.hostname+'/media/catalog/product'+product['image'];
    } else {
      return false;
    }
  }

}



import { Component, OnInit, Output, EventEmitter } from '@angular/core';
import { MessageService } from 'src/app/global/message/message.service';
import { HeaderService } from 'src/app/global/header/header.service';
import { MagentoService } from 'src/app/magento.service';
import { TestBed } from '@angular/core/testing';
import { Router } from '@angular/router';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import * as jQuery from 'jquery';
import { CandyService } from 'src/app/candy.service';

@Component({
  selector: 'app-register',
  templateUrl: './register.component.html',
  styleUrls: ['./register.component.scss']
})
export class RegisterComponent implements OnInit {
  email: String = null ;
  companyName: String = null;
  tradingName: String = null;
  countryRegistered: any = 79;
  EORINumber: String = null;
  regNumber: String = null;
  vatNumber: String = null;
  directorNames: String = null;
  contactName: String = null;
  contactPhone: String = null;

  agreeTerms: boolean = false;
  agreePromotions: boolean = false;

  formError: boolean = false;
  formErrorMessage: String = null;

  searchQuery: String = null;
	addressLine1: String = null;
	addressLine2: String = null;
	addressTown: String = null;
	addressPostcode: string = null;
  addressRegion: String = null;
	addressResults: Array<{ "addressLine2": string, "addressTown": string, "addressPostcode": string }> = [];

  autofill: boolean = false;

  @Output() closePopup = new EventEmitter<boolean>();

	getAddresses() {
		this.http.get('https://api.ideal-postcodes.co.uk/v1/postcodes/' + this.searchQuery + '/?api_key=ak_jwgh8ozzaKXDP2OQgKxvF7rjQdulN').subscribe(response => {
			this.addressResults = response['result'];
			jQuery('.address-finder-results').css('border-bottom-width', '1px');
		});
	}

  selectAddress(addressLine1, addressLine2, addressTown, addressPostcode, addressRegion) {
		this.addressLine1 = addressLine1;
		this.addressLine2 = addressLine2;
		this.addressTown = addressTown;
		this.addressPostcode = addressPostcode;
    this.addressRegion = addressRegion;
		this.addressResults = [];
		this.autofill = true;
	}

  constructor(private message: MessageService, private header: HeaderService, private magento: MagentoService, private router: Router, private http: HttpClient, private candy: CandyService) { }

  closePopupOnClick() {
    jQuery('body').removeClass('disable-scroll')
    this.closePopup.emit();
  }

  setCountryRegistered(country) {
    console.log(country);
    // 79 is code for UK
    this.countryRegistered = 79;
  }

  ngOnInit() {
    jQuery('body').addClass('disable-scroll')
  }

  submitApp() {
    this.formError = false;

    if (!this.companyName || !this.tradingName || !this.countryRegistered || !this.regNumber || !this.vatNumber || !this.EORINumber || !this.email || !this.directorNames || !this.contactName || !this.contactPhone ) {
      this.formError = true;
      this.message.showMessage('Please ensure you have filled in all fields', 'error');
    }

    if (!this.contactName.includes(' ') ) {
      this.formError = true;
      this.message.showMessage('Please ensure a full name is used for contact name', 'error');
    }

    if(!this.formError){
      this.header.setShowLoader(true);

      var promotionsAgreed;
      this.agreePromotions ? promotionsAgreed = 'Yes' : promotionsAgreed = 'No';

      var data = {
                  'data' : {
                    'company': this.companyName,
                    "trading_name": this.tradingName,
                    'country': this.countryRegistered,
                    'company_registration_number': this.regNumber,
                    'company_vat_number': this.vatNumber,
                    "company_eori_number": this.EORINumber,
                    'email_address': this.email,
                    "director_names": this.directorNames,
                    "name" : this.contactName,
                    "firstname": this.contactName,
                    "lastname": this.contactName,
                    'phonenumber': this.contactPhone,
                    'address_line_1': this.addressLine1,
                    'address_line_2': this.addressLine2,
                    'postcode': this.addressPostcode,
                    'city': this.addressTown
                  }

                }

                var candydata = {
                  'company_name': this.companyName,
                  "trading_name": this.tradingName,
                  'country': this.countryRegistered,
                  'registration_number': this.regNumber,
                  'vat_number': this.vatNumber,
                  "eori_number": this.EORINumber,
                  'email': this.email,
                  "directors": this.directorNames,
                  "contact_name" : this.contactName,
                  'contact_phone': this.contactPhone,
                  'delivery_postcode': this.addressPostcode,
                  'delivery_region' : this.addressRegion,
                  'delivery_city': this.addressTown,
                  'delivery_address': this.addressLine1,
                  'delivery_address_two': this.addressLine2,
                  'billing_postcode': this.addressPostcode,
                  'billing_region' : this.addressRegion,
                  'billing_city': this.addressTown,
                  'billing_address': this.addressLine1,
                  'billing_address_two': this.addressLine2,
                }


      this.candy.createRequest('POST','api/v1/registration',candydata).subscribe(response => {
        console.warn(response)
        if(response['message'] == 'OK'){
          this.message.showMessage('Your application has been sent, thank you.', 'success');
          this.closePopupOnClick();
        } else {
          this.message.showMessage(response['error']['errors']['email'][0], 'error');
        }
        this.header.setShowLoader(false);
      }, response => {
        console.warn(response)
        this.header.setShowLoader(false);
        this.message.showMessage(response['error']['errors']['email'][0], 'error');
      }
      )

      // this.magento.createRequest('POST', 'V1/broadlanddigital/customer/send-sign-up-email', data).subscribe(response => {
      //   console.warn(response)
      //   if(response[0] == true){
      //     this.message.showMessage('Your application has been sent, thank you.', 'success');
      //     this.closePopupOnClick();
      //   } else {
      //     this.message.showMessage(response['message'], 'error');
      //   }
      //   this.header.setShowLoader(false);
      // }, response => {
      //   console.warn(response)
      //   this.header.setShowLoader(false);
      //   this.message.showMessage(response['error']['message'], 'error');
      // });
    }
  }

}

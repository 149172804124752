import { compileDirectiveFromRender2 } from '@angular/compiler/src/render3/view/compiler';
import { Component, OnInit, ViewChild, ViewEncapsulation } from '@angular/core';
import { ActivatedRoute, Route, Router } from '@angular/router';
import * as jQuery from 'jquery';
import { CandyService } from 'src/app/candy.service';
import { HeaderService } from 'src/app/global/header/header.service';
import { MessageService } from 'src/app/global/message/message.service';
import { MagentoService } from 'src/app/magento.service';
import { UtilService } from 'src/app/util.service';
import { WorldpayComponent } from 'src/app/worldpay/worldpay.component';
import { DomSanitizer } from '@angular/platform-browser'

@Component({
  selector: 'app-payment',
  templateUrl: './payment.component.html',
  styleUrls: ['../cart.component.scss', '../details/details.component.scss', '../delivery/delivery.component.scss', './payment.component.scss'],
})


export class PaymentComponent implements OnInit {

  cartContents: Array<any> = [];
  totalCheckout: number = null;
  selectedPayment: String = null;
  showPopup: Boolean = false;
  showBillPopup: Boolean = false;
  showPaypalPopup: Boolean = false;
  billTelephone: String = null;
  paypalemail: String = null;
  billingCheckbox: String = null;

  validPromo: Boolean = false;
  promoValue: number = 0;
  promoCode: String = null;


  showTechSpec: boolean = true;

  products: Array<any> = [];
  product_count: Number = 0;
  total: number = 0;
  sub_total: number = 0;
  delivery: number = 0;
  tempTotal: Number = 0;
  tax: Number = 0;

  sameAsShipping: boolean = true;

  deliveryOptionSelected: string = '1';

  marketingOptIn: boolean = true;

  TsCsAgreed: boolean = false;
  isClicked: boolean = false;

  productsImages: Array<any> = [];
  payment_methods: Array<any> = [];
  // selected_payment_method: payment_method_interface;
  selected_payment_method_index: number;
  // address: address_interface;

  // selectedShipping: address_interface;
  // defaultShipping: address_interface;
  showUpdateDefaultShipping: boolean = false;
  customer: any;
  shipping_method: string = null;
  basket_id: String = null;
  vat: number = 0;
  token: String = null;
  discount: number = 0;

  title: string = '';
  firstName: string = '';
  lastName: string = '';
  email: string = '';
  mobile: string = '';
  searchQuery: String = null;
  addressLine1: String = null;
  addressLine2: String = null;
  addressTown: String = null;
  addressPostcode: string = null;
  addresses: Array<any> = [];

  paymentToken: any = null;
  allow_continue: Boolean = false;

  purchaseorderNumber: String = null;

  allowPO: boolean = false;

  worldPayHTML: any = null;

  orderID: any = null;

  taxType: string = null;

  useTPPayNow: boolean = false;

  useTPResponse: any;

  TPReference: string= null;

  orderData: any;

  orderCreated: boolean = false;

  userID: number = null;

  orderFailed: boolean = false;

  @ViewChild('paymentForm', {static: false}) form;

  @ViewChild(WorldpayComponent, {static: false}) worldPay;

  constructor(private router: Router, private headerService: HeaderService, private magento: MagentoService, private util: UtilService, private message: MessageService, private candy: CandyService, private sanitizer: DomSanitizer, private route: ActivatedRoute ) { }

  ngOnInit() {
    var cart = JSON.parse(localStorage.getItem('cart'));
    var url = null;
    this.headerService.setShowLoader(true);

    if (window.location.pathname == '/cart/payment/declined') {
      this.orderFailed = true;
    }

    // get user address
    this.candy.createRequest('GET', 'api/v1/company').subscribe(response=> {
      if (response) {
        this.addresses = response['company'];
        this.userID = response['company']['user_id'];
      }
    })

    // check if user can use PO
    this.candy.createRequest('GET', 'api/modules/worldpay/can_purchase_order').subscribe(response=> {
      if (response == 1) {
        this.allowPO = true;
      } else {
        this.allowPO = false;
        this.selectedPayment = 'tp';
      }
      this.headerService.setShowLoader(false)
    } , error => {
      this.isClicked = false;
      this.message.showMessage('User PO disabled', 'error')
      // this.router.navigateByUrl('/cart/complete/' + this.basket_id);
      this.headerService.setShowLoader(false)
    })

    if (cart) {
      this.basket_id = cart['basket_id'];

      if (this.util.getCookie('customer_token')) {
        this.token = this.util.getCookie('customer_token');
      }

      if(this.basket_id){
        if(this.token){
          url = 'api/v1/baskets/current';
        }
        this.candy.createRequest('GET', url).subscribe(response => {
          console.warn(response);
          if(response){
            this.sub_total = response['data']['sub_total'];
            this.discount = response['data']['discount_total'];
            // this.delivery = response['shipping_amount'];
            // this.vat = response['base_tax_amount'] > 0 && response['grand_total'] > 5000 ? 0 : response['base_tax_amount'] - response['base_shipping_tax_amount'];
            this.vat = response['data']['tax_total'];
            // remove delivery tax from being shown at this point
            // this.total = response['base_subtotal_with_discount'] > 5000 ? response['base_subtotal_with_discount'] : response['base_subtotal_with_discount'] + response['base_tax_amount'] - response['base_shipping_tax_amount'];
            // this.total = response['data']['total'];

            // Note: only fixed standard shipping for now so set on frontend the only possible values
            this.delivery = 9.99;

            // get products, store in array

            this.products = response['data']['lines']['data'];

            this.product_count = this.products.length;
            var basketID = response['data']['id']
            var cart = {
              basket_id: basketID,
              item_count:this.product_count
            }

            // get tax type

            this.taxType = this.products[0]['variant']['data']['tax']['data']['name'];

            this.candy.createRequest('GET', 'api/v1/quotes/basket-price?basket_id='+basketID).subscribe(response => {
              if(response){
                this.sub_total = 0;

                for(var i = 0; i < response.length; i++) {
                  this.products[i].unit_price = parseFloat(response[i].total) / response[i].quantity;
                  this.products[i].variant_id = response[i].product_variant_id;
                  this.sub_total += parseFloat(response[i].total);
                  this.total = this.sub_total;
                }

                this.vat = 0;
                if(this.products[0]) this.products[0].tax ? this.vat = (this.total * 0.2) + 2.00 : this.vat = 2;
                if (this.total >= 5000 && this.taxType === 'Standard') { this.vat = 2}; // remove tax if value minimum 5000, only 2 for delivery vat
                this.total += this.vat + this.delivery;
                this.headerService.setShowLoader(false);
              }
            }, error => {
              this.headerService.setShowLoader(false);
            });

            this.headerService.setShowLoader(false);
          }
        }, response => {
          this.headerService.setShowLoader(false);
          this.message.showMessage('There was an issue loading your basket', 'error');
        });

      } else {
        this.headerService.setShowLoader(false);
      }
    }
  }

  getShippingAddressInfo(address) {
    this.email = this.customer['email'];
    this.firstName = this.customer['firstname'];
    this.lastName = this.customer['lastname']
    var defaultShippingAddress = address;

    if (defaultShippingAddress['prefix']) {
      this.title = defaultShippingAddress['prefix'];
    }

    console.warn(defaultShippingAddress);

    // set address fields and hide address finder if address exists
    if (defaultShippingAddress['street'][0]) {
      this.addressLine1 = defaultShippingAddress['street'][0];
    }

    if (defaultShippingAddress['street'][1]) {
      this.addressLine2 = defaultShippingAddress['street'][1];
    }

    if (defaultShippingAddress['city']) {
      this.addressTown = defaultShippingAddress['city'];
    }

    if (defaultShippingAddress['postcode']) {
      this.addressPostcode = defaultShippingAddress['postcode'];
    }

    if (defaultShippingAddress['telephone']) {
      this.mobile = defaultShippingAddress['telephone'];
    }
  }

  selectMethod(method) {
    this.purchaseorderNumber = null;
    this.selectedPayment = method;
    this.allow_continue = false;
    // this.TsCsAgreed = false;

    if (this.selectedPayment == 'po') {
      this.useTPPayNow = false;
    }
  }

  checkPO(){
    if(this.purchaseorderNumber ){
      this.allow_continue = true;
    } else {
      this.allow_continue = false;
    }
  }

  openChequePopup() {
    this.showPopup = true;
    this.showBillPopup = true;
  }

  closePopup(){
    this.showPopup = false;
  }

  receiveToken(e) {
    console.warn(e);
    this.paymentToken = e;
  }

  toggleTsCs() {
    this.headerService.setShowLoader(true);

    if (!this.selectedPayment) {
      this.TsCsAgreed = false;
      this.message.showMessage('Please select a payment method first', 'error');
      return;
    } else if (this.selectedPayment == 'tp') {
      this.purchaseorderNumber = null;
      this.readyTakePayment();
    }

    if (!this.TsCsAgreed) {
      this.useTPPayNow = false;
    }

    this.TsCsAgreed = !this.TsCsAgreed;

    console.warn(this.TsCsAgreed);


    // can only be created ONCE to prevent duplicate GC orders

    if (this.TsCsAgreed == true && this.selectedPayment == 'tp') {
      setTimeout(()=> {
        this.submitPayment();
      }, 3000)
    } else {
      this.headerService.setShowLoader(false);
    }

    // setTimeout(() => {
    //   if (this.orderCreated == false) {
    //     console.warn('MAKING GC ORDER ATTEMPT');
    //     this.submitPayment();
    //   } else {
    //     this.headerService.setShowLoader(false)

    //   }
    //   this.orderCreated = true;
    // }, 2000);
  }

  checkToken() {
    if (this.selectedPayment == 'po' && this.allowPO) {
      this.submitPayment();
    } else {
      this.worldPay.submitForm();
    }
    this.isClicked = true;
  }

  submitPayment() {
    this.headerService.setShowLoader(true);

    var unitCosts = [];

    for(var item of this.products) {
      unitCosts.push( {
        id: item.variant_id,
        price: item.unit_price*100 //in pennies
      });
    }

    // check user has completed either payment method
    if (this.selectedPayment == 'tp' || this.purchaseorderNumber) {
      this.orderData =
          {
            "price": Number(this.total.toFixed(2)),
            "city": this.addresses['billing_city'],
            "address": this.addresses['billing_address'] + ' ' + this.addresses['billing_address_two'],
            "description": this.basket_id,
            "name": this.addresses['contact_name'],
            "po_number": null,
            "base_url": window.location.origin,
            "tax_type": this.taxType,
            "tax_total": this.vat * 100, //in pennies
            "basket_id": this.basket_id,
            "unit_costs": unitCosts
          }

      if (this.purchaseorderNumber) {
        this.orderData['purchase_order'] = 1;
        this.orderData['po_number'] = this.purchaseorderNumber;
      } else {
        delete this.orderData['purchase_order'];
      }

      this.candy.createRequest('POST', 'api/v1/orders', {'basket_id': this.basket_id}).subscribe(response => {
        this.orderID = response['data']['id'];
        this.orderData['order_id'] = this.orderID;

        this.removeDuplicates();

        if (this.selectedPayment) {
          this.candy.createRequest('PATCH', `api/takepayments/order/update/${this.orderID}?user_id=${this.userID}&reference=${this.TPReference ? this.TPReference : null}&tax_type=${this.taxType}&ponumber=${this.purchaseorderNumber ? this.purchaseorderNumber: null}`).subscribe(response => {

            // this will attach  a numeric takepayment ID so the GC order made can be found later when submitted
            this.orderData['transactionUnique'] = this.TPReference;
            this.orderData['order_id'] = response['order']['id'];
            setTimeout(()=>{
              this.headerService.setShowLoader(false)
            }, 1000)
          })
        }
      })
    }   else {
      this.isClicked = false;
      this.headerService.setShowLoader(false)
      if (this.selectedPayment == 'tp') {
        this.message.showMessage('Please ensure you have filled in your payment details', 'error');
      } else {
        this.message.showMessage('Please enter a purchase order number ', 'error');
      }
    }
  }

  removeDuplicates() {
    this.candy.createRequest('POST', 'api/takepayments/order/remove-duplicates/'+this.orderID, null).subscribe(response => {})
  }

  completeOrderPO() {
    this.headerService.setShowLoader(true);
    var unitCosts = [];

    for(var item of this.products) {
      unitCosts.push( {
        id: item.variant_id,
        price: item.unit_price*100  //in pennies
      });
    }

    if (this.selectedPayment == 'po' && this.purchaseorderNumber) {
      this.orderData =
          {
            "price": Number(this.total.toFixed(2)),
            "city": this.addresses['billing_city'],
            "address": this.addresses['billing_address'] + ' ' + this.addresses['billing_address_two'],
            "description": this.basket_id,
            "name": this.addresses['contact_name'],
            "purchase_order": 0,
            "po_number": null,
            "base_url": window.location.origin,
            "tax_type": this.taxType,
            "tax_total": this.vat * 100, //in pennies
            "basket_id": this.basket_id,
            "unit_costs": unitCosts
          }

      if (this.purchaseorderNumber) {
        this.orderData['purchase_order'] = 1;
        this.orderData['po_number'] = this.purchaseorderNumber;
      }

      this.candy.createRequest('POST', 'api/v1/orders', {'basket_id': this.basket_id}).subscribe(response => {
        this.orderID = response['data']['id'];
        this.orderData['order_id'] = this.orderID;
        this.candy.createRequest('PATCH', `api/takepayments/order/update/${this.orderID}?user_id=${this.userID}&reference=${null}&tax_type=${this.taxType}&ponumber=${this.purchaseorderNumber ? this.purchaseorderNumber: null}`).subscribe(response => {

          // this will attach  a numeric takepayment ID so the GC order made can be found later when submitted
          this.orderData['transactionUnique'] = this.TPReference;
          this.orderData['order_id'] = response['order']['id'];
          this.candy.createRequest('POST', 'api/takepayments/submit', this.orderData).subscribe(response=> {
            this.headerService.setShowLoader(false);
            if(response['status'] == '3DS') {
              this.router.navigate(
                  ['/threeds-redirect'],
                  {queryParams: {worldpayOrderCode: response['worldpayOrderCode'], oneTime3DsToken: response['oneTime3DsToken'], redirectURL: response['redirectURL'], sessionID: response['session_id']}}
              );
            } else {
              // send order email now needs to be in cart/complete because of 3DS re-direct
              this.router.navigateByUrl('/cart/complete/' + response['orderId']);
            }
            this.headerService.setShowLoader(false);
          } , error => {
            this.isClicked = false;
            this.message.showMessage('Error in sending payment, please try again', 'error')
            // this.router.navigateByUrl('/cart/complete/' + this.basket_id);
            this.headerService.setShowLoader(false)
          })
          setTimeout(()=>{
            this.headerService.setShowLoader(false)
          }, 1000)
        })
      })
    }
  }

  readyTakePayment() {
    this.headerService.setShowLoader(true);

    var data =
        {
          "amount": Number(this.total.toFixed(2)),
          "city": this.addresses['billing_city'],
          "address": this.addresses['billing_address'] + ' ' + this.addresses['billing_address_two'],
          "description": this.basket_id,
          "name": this.addresses['contact_name'],
          "purchase_order": 0,
          "po_number": null,
          "base_url": window.location.origin,
          "tax_type": this.taxType,
          "basket_id": this.basket_id
        }

    this.candy.createRequest('POST-TP', 'api/takepayments', data).subscribe(response=> {
      // this.candy.createRequest('POST-TP',`api/takepayments/?amount=1203&card_number=4012001037141112&card_expiry_month=12&card_expiry_year=22&card_cvv=083&customer_address=16&customer_post_code=155` , data).subscribe(response=> {
      this.useTPPayNow = true;
      this.useTPResponse = this.sanitizer.bypassSecurityTrustHtml(JSON.parse(response));

      setTimeout(()=> {
        var uniqueTPRef = document.querySelectorAll("input[name=transactionUnique]")[0]['defaultValue'];

        this.TPReference = uniqueTPRef;

        // so we can update the TP order with the new quote item values
        this.candy.createRequest('GET', 'api/v1/quotes/get-basket-quote?basket_id='+this.basket_id).subscribe(response=> {
          if(response['quote']) {
            this.candy.createRequest('PATCH', 'api/v1/quotes/update-quote-reference', {quote_id: response['quote'].id, reference: this.TPReference}).subscribe();
          }
        });
      }, 1000)
    }, err => {
      console.warn(err);
    })
  }
}

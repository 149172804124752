import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { HttpClient } from '@angular/common/http';

import * as jQuery from 'jquery';
import { HeaderService } from 'src/app/global/header/header.service';
import { UtilService } from 'src/app/util.service';
import { MagentoService } from 'src/app/magento.service';
import { CandyService } from 'src/app/candy.service';
import { MessageService } from 'src/app/global/message/message.service';

@Component({
	selector: 'app-complete',
	templateUrl: './complete.component.html',
	styleUrls: ['../cart.component.scss', './complete.component.scss']
})
export class CompleteComponent implements OnInit {

  order_id: String = null;
  email: string = null;

  constructor(private route: ActivatedRoute, private magento: MagentoService, private util: UtilService, private header: HeaderService, private candy: CandyService, private message: MessageService) { }

  ngOnInit() {
    // get user address
    this.order_id = this.route.snapshot.params['id'];

    this.candy.createRequest('GET', 'api/v1/company').subscribe(response=> {
      if (response) {
        this.email = response['company']['email'];
        this.sendOrderEmail(this.email, this.order_id);
      }
    })

    localStorage.clear();
    this.header.setShowCartDot(false);
    this.makeNewCart();
  }

  makeNewCart(){
    this.candy.createRequest('POST', 'api/v1/baskets', {}).subscribe(r=>{
      this.candy.createRequest('POST', `api/v1/baskets/${r['data']['id']}/claim`).subscribe(t=>{
      })
    })
  }

  sendOrderEmail(email, order_id) {
    this.candy.createRequest('POST', 'api/modules/mail/order', {'email': email, 'order_id': order_id}).subscribe(response=> {
      console.warn(response);
      // if order is made via WP, then also send the invoice immediately
      if (response['order']['payment_method'] == 'takepayments') {
        this.sendInvoiceEmail(this.email, this.order_id);
      }
     } , error => {
      this.message.showMessage('Error in sending order email', 'error')
    })
  }

  sendInvoiceEmail(email, order_id) {
    this.candy.createRequest('POST', 'api/modules/mail/invoice', {'email': email, 'order_id': order_id}).subscribe(response=> {
      console.warn(response);
     } , error => {
      this.message.showMessage('Error in sending invoice email', 'error')
    })
  }

  // makeNewCart(){
  //   // creates new cart with quote ID if existing logged in customer wishes to make another order
  //   var promise = new Promise((resolve, reject) => {
  //     this.magento.createRequest('GET', 'V1/customers/me', null, this.util.getCookie("customer_token")).subscribe(response => {
  //       if(response['id']){
  //         this.util.setCookie('customer', JSON.stringify(response), 1); //4 = 4 hours to match magento's token timeout
  //         this.magento.createRequest('GET', 'V1/carts/mine', null, this.util.getCookie("customer_token")).subscribe(response => {
  //           if(response){
  //             localStorage.setItem('cart', JSON.stringify({basket_id: response['id'], item_count: response['items_count']}));
  //           }
  //         }, response => {
  //           reject(response);
  //         });
  //         resolve(JSON.stringify(response));
  //       }
  //     }, response => {
  //         reject('Incorrect username or password');
  //     });
  //   });

  //   return promise;

  // }
}

import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-popup',
  templateUrl: './popup.component.html',
  styleUrls: ['./popup.component.scss']
})
export class PopupComponent implements OnInit {

  showPopup: Boolean = false;
  @Input() MOQWidth: boolean;

  constructor() { }

  ngOnInit() {
  }

  openPopup(){
    this.showPopup = true;
  }

  closePopup(){
    this.showPopup = false;
  }

}

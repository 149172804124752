import { Component, OnInit, ViewChild } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Router } from '@angular/router';
import { ActivatedRoute } from '@angular/router';

import * as jQuery from 'jquery';
import { HeaderService } from 'src/app/global/header/header.service';

import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MagentoService } from 'src/app/magento.service';
import { UtilService } from 'src/app/util.service';
import { MessageService } from 'src/app/global/message/message.service';
import { error } from 'jquery';
import { CandyService } from 'src/app/candy.service';

export interface payment_method_interface {
  code: String;
  title: String;
}

export interface address_interface{
  firstname: String;
  lastname: String;
  street: [{},{}];
  city: String;
  postcode: String;
  telephone: String;
}

@Component({
	selector: 'app-details',
	templateUrl: './details.component.html',
	styleUrls: ['../cart.component.scss', './details.component.scss']
})
export class DetailsComponent implements OnInit {

	isLoggedIn: Boolean = false;
	loggedInUser: Object = null;
	autofill: Boolean = false;
	cartContents: Array<any> = [];
	totalCheckout: number = null;

	userAddressLine1: String = null;
	userAddressLine2: String = null;
	userAddressTown: String = null;
	userAddressPostcode: String = null;

	validPromo: Boolean = false;
	promoValue: number = 0;
	promoCode: String = null;

	aFormGroup: FormGroup;

  showTechSpec: boolean = true;
	showPopup: Boolean = false;

  products: Array<any> = [];
  product_count: Number = 0;
  vat: number = null;
  total: number = 0;
  sub_total: number = 0;
  delivery: number = 0;

  // sameAsShipping: boolean = true;

  title: string = '';
  firstName: string = '';
  lastName: string = '';
  name: string = '';
  email: string = '';
  mobile: string = '';

  searchQuery: String = null;
	addressLine1: String = null;
	addressLine2: String = null;
	addressTown: String = null;
	addressPostcode: string = null;
	addressResults: Array<{ "addressLine2": string, "addressTown": string, "addressPostcode": string }> = [];

  searchQueryBilling: String = null;
  billingAddressLine1: String = null;
	billingAddressLine2: String = null;
	billingAddressTown: String = null;
	billingAddressPostcode: string = null;
  billingAddressMobile: string = null;

	billingAddressResults: Array<{ "billingAddressLine2": string, "billingAddressTown": string, "billingAddressPostcode": string }> = [];

  productsImages: Array<any> = [];
  payment_methods: Array<any> = [];
  selected_payment_method: payment_method_interface;
  selected_payment_method_index: number;
  address: address_interface;

  selectedShipping: address_interface;
  defaultShipping: address_interface;
  defaultShippingIndex: number
  showUpdateDefaultShipping: boolean = false;
  customer: any;
  shipping_method: string = null;
  basket_id: String = null;
  token: String = null;
  discount: number = 0;

  billingAddress: address_interface;

  addresses: Array<any> = [];


	constructor(private http: HttpClient, private router: Router, private route: ActivatedRoute, private headerService: HeaderService,  private magento: MagentoService, private util: UtilService, private message: MessageService, private candy: CandyService) {
	}

  ngOnInit() {
    var cart = JSON.parse(localStorage.getItem('cart'));

    // old metpro way - problem is when address updated this will still use the old one
    // this.customer = JSON.parse(this.util.getCookie('customer'));
    var url = null;
    this.headerService.setShowLoader(true);

    // get user details and address
    this.candy.createRequest('GET', 'api/v1/company').subscribe(response=> {
      console.warn(response);
      if (response) {
        var address = response['company'];
        this.addressLine1 = address.delivery_address;
        this.addressLine2 = address.delivery_address_two;
        this.addressTown = address.delivery_city;
        this.addressPostcode = address.delivery_postcode;
        this.billingAddressLine1 = address.billing_address;
        this.billingAddressLine2 = address.billing_address_two;
        this.billingAddressTown = address.billing_city;
        this.billingAddressPostcode = address.billing_postcode;

        this.name = address.contact_name;
        this.email = address.email;
        this.mobile = address.contact_phone;
      }
    })

    if (cart) {
      this.payment_methods = cart['payment_methods'];
      this.shipping_method = cart['shipping_method'];

      this.basket_id = cart['basket_id'];
      // this.defaultShipping = cart['address_information']['addressInformation']['shipping_address'];

      if (this.util.getCookie('customer_token')) {
        this.token = this.util.getCookie('customer_token');
      }

      if(this.basket_id){

        if(this.token){
          url = 'api/v1/baskets/current';
        }
        // GC API

        this.candy.createRequest('GET', url).subscribe(response => {
          console.warn(response);
          if(response){
            this.sub_total = response['data']['sub_total'];
            this.discount = response['data']['discount_total'];
            // this.delivery = response['shipping_amount'];
            // this.vat = response['base_tax_amount'] > 0 && response['grand_total'] > 5000 ? 0 : response['base_tax_amount'] - response['base_shipping_tax_amount'];
            this.vat = response['data']['tax_total'];
            // remove delivery tax from being shown at this point
            // this.total = response['base_subtotal_with_discount'] > 5000 ? response['base_subtotal_with_discount'] : response['base_subtotal_with_discount'] + response['base_tax_amount'] - response['base_shipping_tax_amount'];
            this.total = response['data']['total'];

            // get products, store in array

            this.products = response['data']['lines']['data'];

            var allProductsCellular = false; // Flag to check if all products are cellular

            this.products.forEach((product) => {
              product['img_url'] = 'loading';
              this.candy.getProductImagesWithCustomDetails(product['variant']['data']['product']['data']['model-id']).then((res) => {
                product['img_url'] = res['image'];
                product['custom_details'] = res['custom_details'];
                // Check if all products are cellular
                for (const product of this.products) {
                  if (
                    product['custom_details'] !== null &&
                    product['custom_details'] !== undefined && // Add this check
                    product['custom_details'].hasOwnProperty('is_cellular') &&
                    product['custom_details']['is_cellular']
                  ) {
                    allProductsCellular = true;
                    break;
                  }
                }
              }).catch(error => {
                console.log(error);
              });
            });

            this.product_count = this.products.length;
            var basketID = response['data']['id']
            var cart = {
              basket_id: basketID,
              item_count:this.product_count
            };

            localStorage.setItem('cart', JSON.stringify(cart));

            var taxType = this.products[0]['variant']['data']['tax']['data']['name'];

            this.candy.createRequest('GET', 'api/v1/quotes/basket-price?basket_id='+basketID).subscribe(response => {
              if (response) {
                this.sub_total = 0;

                for (var i = 0; i < response.length; i++) {
                  this.products[i].unit_price = parseFloat(response[i].total) / response[i].quantity;
                  this.sub_total += parseFloat(response[i].total);
                  this.total = this.sub_total;
                }

                this.vat = 0;
                if (allProductsCellular && this.total >= 5000 && taxType === 'Standard') {
                  this.vat = 0;
                } else {
                  if (this.products[0]) { this.products[0].tax ? this.vat = this.total * 0.2 : this.vat = 0; }
                  if (this.total >= 5000) { this.vat = 0; } // remove tax if value minimum 5000, only 2 for delivery vat
                  if (this.total >= 5000 && taxType === 'Standard') { this.vat = 0; } // remove tax if value minimum 5000 & is standard vat
                }
                this.total += this.vat;
                this.headerService.setShowLoader(false);
              }
            }, error => {
              this.headerService.setShowLoader(false);
            });
          }
        }, response => {
          this.headerService.setShowLoader(false);
          this.message.showMessage('There was an issue loading your basket', 'error');
        });

      } else {
        this.headerService.setShowLoader(false);
      }

    } else {
      this.headerService.setShowLoader(false);
      this.router.navigateByUrl('/');
      this.message.showMessage('No cart session found', 'error', true)
    }
  }

  getShippingAddressInfo(address) {
    // var defaultShippingAddress = customer['addresses'][0];

    this.email = this.customer['email'];
    this.firstName = this.customer['firstname'];
    this.lastName = this.customer['lastname']

      // var billingAddress = customer['billing_address'];
      var defaultShippingAddress = address;
      this.defaultShipping = address;
      if (defaultShippingAddress['prefix']) {
        this.title = defaultShippingAddress['prefix'];
      }

      // set address fields and hide address finder if address exists
      if (defaultShippingAddress['street'][0]) {
        this.addressLine1 = defaultShippingAddress['street'][0];
      }

      if (defaultShippingAddress['street'][1]) {
        this.addressLine2 = defaultShippingAddress['street'][1];
      }

      if (defaultShippingAddress['city']) {
        this.addressTown = defaultShippingAddress['city'];
      }

      if (defaultShippingAddress['postcode']) {
        this.addressPostcode = defaultShippingAddress['postcode'];
      }

      if (defaultShippingAddress['telephone']) {
        this.mobile = defaultShippingAddress['telephone'];
      }
  }

  getBillingAddressInfo(address) {

    var defaultBillingAddress = address;

    if (defaultBillingAddress['street']) {
      this.billingAddressLine1 = defaultBillingAddress['street'][0];
      // only if second line exists
      if (defaultBillingAddress['street'][1]) {
        this.billingAddressLine2 = defaultBillingAddress['street'][1];
      }
    }

    this.billingAddressPostcode = defaultBillingAddress['postcode'];
    this.billingAddressTown = defaultBillingAddress['city'];
    this.billingAddressMobile = defaultBillingAddress['telephone'];
  }

  getTotals () {
    var url = 'default/V1/carts/mine/totals';

    // GC API
    // url = 'baskets/current';

    this.magento.createRequest('GET', url, null, this.token).subscribe(response => {
      this.sub_total = response['subtotal'];
      this.discount = response['discount'];
      this.delivery = response['shipping_amount'];
      this.vat = response['base_tax_amount'] > 0 && response['grand_total'] > 5000 ? 0 : response['base_tax_amount'] - response['base_shipping_tax_amount'] ;
       // remove delivery tax from being shown at this point
       this.total = response['base_subtotal_with_discount'] > 5000 ? response['base_subtotal_with_discount'] : response['base_subtotal_with_discount'] + response['base_tax_amount'] - response['base_shipping_tax_amount'];
    })
  }

  getProductOptions(skus) {
    var data = {
      'data': skus
    }
    this.magento.createRequest('POST', 'V1/broadlanddigital/product/get-custom-attribute-values', data).subscribe(response => {
      var productOptions = response;
      productOptions.forEach(cartItem => {
        this.products.forEach(device => {
          if (Object.keys(cartItem)[0] == device.sku) {
            device['options'] = Object.values(cartItem)[0];
          }
        })
        console.log(this.products);

      });
      this.headerService.setShowLoader(false);
    })
  }

	getAddresses() {
		this.http.get('https://api.ideal-postcodes.co.uk/v1/postcodes/' + this.searchQuery + '/?api_key=ak_jwgh8ozzaKXDP2OQgKxvF7rjQdulN').subscribe(response => {
			this.addressResults = response['result'];
			jQuery('.address-finder-results').css('border-bottom-width', '1px');
		});
	}

	getBillingAddresses() {
		this.http.get('https://api.ideal-postcodes.co.uk/v1/postcodes/' + this.searchQueryBilling + '/?api_key=ak_jwgh8ozzaKXDP2OQgKxvF7rjQdulN').subscribe(response => {
			this.billingAddressResults = response['result'];
			jQuery('.billing-address-finder-results').css('border-bottom-width', '1px');
		});
	}

	selectAddress(addressLine1, addressLine2, addressTown, addressPostcode) {
		this.addressLine1 = addressLine1;
		this.addressLine2 = addressLine2;
		this.addressTown = addressTown;
		this.addressPostcode = addressPostcode;
		this.addressResults = [];
		this.autofill = true;
		jQuery('.address-finder-results').css('border-bottom-width', '0');
		jQuery('.logged-in-address').hide();
		jQuery(".address-error").hide();
		// jQuery(".autofill-address").hide();
		// jQuery(".autofill-address").fadeIn();
	}

  selectBillingAddress(addressLine1, addressLine2, addressTown, addressPostcode) {
		this.billingAddressLine1 = addressLine1;
		this.billingAddressLine2 = addressLine2;
		this.billingAddressTown = addressTown;
		this.billingAddressPostcode = addressPostcode;
		this.billingAddressResults = [];
		this.autofill = true;
		jQuery('.address-finder-results').css('border-bottom-width', '0');
		jQuery('.logged-in-address').hide();
		jQuery(".address-error").hide();
		// jQuery(".autofill-address").hide();
		// jQuery(".autofill-address").fadeIn();
	}

	showAutofill() {
		jQuery(".autofill-address").hide();
		jQuery(".autofill-address").fadeIn();
		jQuery('.logged-in-address').hide();
		this.autofill = true;
		this.addressResults = [];
		this.addressPostcode = jQuery(".address-finder-form input").val().toString();
	}

	continueCheckout() {
    this.router.navigateByUrl('/cart/delivery');
	}

  updateShippingAddress() {
    this.router.navigateByUrl('/cart/delivery');

    // assign input values to default shipping address
    this.defaultShipping['prefix'] = this.title;
    this.defaultShipping['firstname'] = this.firstName;
    this.defaultShipping['lastname'] = this.lastName;
    this.defaultShipping['city'] = this.addressTown;
    this.defaultShipping['postcode'] = this.addressPostcode;
    this.defaultShipping['street'][0] = this.addressLine1;
    this.defaultShipping['street'][1] = this.addressLine2;

    this.customer['addresses'][this.defaultShippingIndex] = this.defaultShipping;

    var data = {
      "customer": {
          "id": this.customer["id"],
          "email": this.customer["email"],
          "firstname": this.customer["firstname"],
          "lastname": this.customer["lastname"],
          //"default_billing":  this.customer["default_billing"],
          "default_shipping": this.customer["default_shipping"],
          "website_id": 1,
          "addresses": this.customer["addresses"]
      }
    };

    this.router.navigateByUrl('/cart/delivery');

    if (data!=null) {
      this.headerService.setShowLoader(true);

      // GC API
      // url = 'user/addresses';

      this.magento.createRequest('PUT', 'V1/customers/me', data, this.token ).subscribe(response => {
      this.headerService.setShowLoader(false);
      // this.message.showMessage('Your address details have been updated.', 'success', true);
      this.util.setCookie('customer', JSON.stringify(response), 1); //4 = 4 hours to match magento's token timeout


      this.customer = response;
      this.addresses = this.customer['addresses'];

      this.showUpdateDefaultShipping = false;
      this.router.navigateByUrl('/cart/delivery');
      })
    } else {
      this.showUpdateDefaultShipping = false;
    }
  }

	recalculateTotals() {
		this.cartContents = JSON.parse(localStorage.getItem("cart"));

		this.totalCheckout = 0;

		for (let item of this.cartContents) {
			this.totalCheckout = this.totalCheckout + (item.quantity * item.price);
		}

		if (this.promoValue > 1) {
			this.totalCheckout += this.promoValue;
		}
	}
}

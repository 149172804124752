import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-vat-explained',
  templateUrl: './vat-explained.component.html',
  styleUrls: ['./vat-explained.component.scss']
})
export class VatExplainedComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}

import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';
import { HeaderService } from '../header/header.service';

@Injectable({
  providedIn: 'root'
})
export class MessageService {
  message = new Subject();
  type = new Subject();

  constructor(private headerService: HeaderService) { }

  showMessage(message, type, autohide = false){
    this.message.next(message);
    this.type.next(type);
    this.headerService.setShowMessage(true);
    if(autohide == true){
      setTimeout(() => {
        this.headerService.setShowMessage(false);
        setTimeout(() => {
          this.message.next(null);
          this.type.next(null);
        }, 1000);
      }, 5000)
    }
  }
}


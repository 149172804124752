import { BrowserModule } from '@angular/platform-browser';
import { NgModule, OnInit, ChangeDetectorRef } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { Routes, RouterModule, Router } from '@angular/router';
import { HttpClientModule } from '@angular/common/http';
import * as jQuery from 'jquery';

import { SiteWrapper } from './wrapper.component';
import { HeaderComponent } from './global/header/header.component';
import { FooterComponent } from './global/footer/footer.component';
import { HeaderService } from './global/header/header.service';
import { FooterService } from './global/footer/footer.service';
import { CartComponent } from './cart/cart.component';
import { DetailsComponent } from './cart/details/details.component';
import { PaymentComponent } from './cart/payment/payment.component';
import { CompleteComponent } from './cart/complete/complete.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { ContactusComponent } from './contactus/contactus.component';
import { PrivacypolicyComponent } from './privacypolicy/privacypolicy.component';
import { CookiepolicyComponent } from './cookiepolicy/cookiepolicy.component';
import { TermscondtionsComponent } from './termscondtions/termscondtions.component';
import { HomepageComponent} from './homepage/homepage.component';
import { HomepageService } from './homepage/homepage.service';
import { DeliveryComponent } from './cart/delivery/delivery.component';
import { NgxCSVtoJSONModule } from 'ngx-csvto-json';
import { ProductsComponent } from './products/products.component';
import { DeviceComponent } from './products/device/device.component';
import { LoaderComponent } from './global/loader/loader.component';
import { MessageComponent } from './global/message/message.component';
import { LoginComponent } from './login/login.component';
import { AccountComponent } from './account/account.component';
import { PopupComponent } from './global/popup/popup.component';
import { RegisterComponent } from './account/register/register.component';
import { OrdersComponent } from './account/orders/orders.component';
import { SessionService } from './session.service';
import { WorldpayComponent } from './worldpay/worldpay.component';
import { UtilService } from './util.service';
import { AboutusComponent } from './aboutus/aboutus.component';
import { GradingComponent } from './grading/grading.component';
import { VatExplainedComponent } from './vat-explained/vat-explained.component';
import { PasswordresetComponent } from './passwordreset/passwordreset.component';
import { SortPipe } from './global/pipes/sort.pipe';
import { ThreeDSComponent } from './cart/payment/three-ds/three-ds.component';
import { ThreedsRedirectComponent } from './threeds-redirect/threeds-redirect.component';
import { QuotesComponent } from './account/quotes/quotes.component';
import { CookieService } from 'ngx-cookie-service';
import {QuoteExpiredComponent} from './quote-expired/quote-expired.component';

const siteRoutes: Routes = [
  {path: '', component: HomepageComponent},
  {path: 'about-us' ,  component: AboutusComponent},
  {path: 'account' ,  component: AccountComponent},
  {path: 'account/orders' ,  component: OrdersComponent},
  {path: 'account/quotes' ,  component: QuotesComponent},

  {path: 'cart', component: CartComponent},
  {path: 'cart/details', component: DetailsComponent},
  {path: 'cart/payment', component: PaymentComponent},
  {path: 'cart/payment/3ds', component: ThreeDSComponent},

  {path: 'cart/payment/declined', component: PaymentComponent},

  {path: 'cart/delivery', component: DeliveryComponent},
  {path: 'cart/complete/:id', component: CompleteComponent},

  {path: 'login' , component: LoginComponent},
  {path: 'register' , component: LoginComponent},
  {path: 'contact-us', component: ContactusComponent},
  {path: 'quote/expired', component: QuoteExpiredComponent},
  {path: 'privacy-policy', component: PrivacypolicyComponent},
  {path: 'cookie-policy', component: CookiepolicyComponent},
  {path: 'terms-and-conditions', component: TermscondtionsComponent},

  // redirect to Apple category if user tries to enter page manually
  {path: 'products' , redirectTo : '/products/Apple' , pathMatch : 'full'},
  {path: 'products/:brand/:parentId', component: ProductsComponent},
  {path: 'products/:brand/product/:url', component: DeviceComponent},
  // :extra is to handle SKUs such as SAM_SM-A025F/DS due to the /
  {path: 'products/:brand/:url/:extra', component: DeviceComponent},

  {path: 'grading', component: GradingComponent},
  {path: 'vat-explained', component: VatExplainedComponent},
  {path: 'password-reset', component: PasswordresetComponent},

  {path: 'threeds-redirect', component: ThreedsRedirectComponent},


  {path: '**', component: HomepageComponent}
];

@NgModule({
  declarations: [
    SiteWrapper,
    HeaderComponent,
    FooterComponent,
    AccountComponent,
    CartComponent,
    DetailsComponent,
    PaymentComponent,
    CompleteComponent,
    ContactusComponent,
    PrivacypolicyComponent,
    CookiepolicyComponent,
    TermscondtionsComponent,
    QuoteExpiredComponent,
    HomepageComponent,
    PopupComponent,
    DeliveryComponent,
    ProductsComponent,
    DeviceComponent,
    LoaderComponent,
    MessageComponent,
    LoginComponent,
    RegisterComponent,
    OrdersComponent,
    QuotesComponent,
    WorldpayComponent,
    AboutusComponent,
    GradingComponent,
    VatExplainedComponent,
    ThreedsRedirectComponent,
    PasswordresetComponent,
    ThreeDSComponent,
    SortPipe
  ],
  imports: [
    BrowserModule,
    RouterModule.forRoot(siteRoutes, {scrollPositionRestoration: 'enabled'}),
	  FormsModule,
    ReactiveFormsModule,
    HttpClientModule,
    BrowserAnimationsModule,
    NgxCSVtoJSONModule,
  ],
  providers: [HeaderService, FooterService, HomepageService, PopupComponent, CookieService],
  bootstrap: [SiteWrapper],


})
export class AppModule{
  constructor(private router: Router, private session: SessionService, private util: UtilService){
    setTimeout(()=>{
      localStorage.clear();
      this.util.deleteCookie('customer');
      this.util.deleteCookie('customer_token');
      this.router.navigateByUrl('/login');
    }, 60000 * 30);

    setInterval(()=> {
      if ( window.location.pathname != '/password-reset') {
        if(!this.session.isLoggedIn() && window.location.pathname != '/login' && window.location.pathname != '/register' && window.location.pathname != '/privacy-policy' && window.location.pathname != '/quote/expired') {
          localStorage.removeItem('cart');
          this.router.navigateByUrl('/login')
        }
      }
    },1000);

    setInterval(() => {
        if (!this.session.isLoggedIn()) {
          // if is not loggedIn
          // find id of the element = 'jsd-widget' and set display:none
          const element = document.getElementById('jsd-widget');
          if (element != null) {
            element.style.display = 'none';
          }
        } else {
            // if is loggedIn
            // find id of the element = 'jsd-widget' and set display:block
            const element = document.getElementById('jsd-widget');
            if (element != null) {
                element.style.display = 'block';
            }
        }
    }, 1000);
  }
}

// Node cannot be found in the current page
